<template lang="pug">
  main#map
    b-header
    grey-banner

    el-dialog(
      :visible="dialogClient != null"
      center
      :show-close="false"
      @close="closeDialog"
    )
      .back(
        @click="closeDialog"
      ) torna indietro

      client-order(
        :client="dialogClient"
      )

      el-button.centre(
        @click="closeDialog"
        type="primary"
      )
        | Chiudi

    .container
      .head
        h3 Mappa Piazze
        .filters
          label Filtra
          el-select(
            v-model="filters.province"
            multiple
            filterable
            collapse-tags
            placeholder="provincia"
            @change="filterMap"
            allow-create
            clearable
          )
            el-option(
              v-for="province in provinces"
              :key="province"
              :label="province"
              :value="province"
            )
          el-select(
            v-model="filters.season_year"
            filterable
            placeholder="stagione"
            @change="filterMap"
            clearable
          )
            el-option(
              v-for="season in seasons"
              :key="season.season+''+season.year"
              :label="season.season+''+season.year"
              :value="season.season+''+season.year"
            )
          el-select(
            v-model="filters.client"
            filterable
            placeholder="cliente"
            @change="filterMap"
            clearable
          )
            el-option(
              v-for="client in mapData"
              :key="client.id"
              :label="client.corporate_name"
              :value="client.id"
            )
          el-select(
            v-model="filters.brand"
            multiple
            filterable
            collapse-tags
            placeholder="brand"
            @change="filterMap"
            allow-create
            clearable
          )
            el-option(
              v-for="brand in brandsList"
              :key="brand.id"
              :label="brand.name"
              :value="brand.id"
            )
      .row
        aside
          gmap-map(
            :center="center"
            :zoom="10"
          )
            gmap-marker(
              :key="index"
              v-for="(m, index) in markers"
              :position="m"
              @click="setNewCenter(m)"
              :icon="'/geocode-'+m.status+'.png'"
            )

          ul.legend
            li
              i.circ.ca
              span Attivo
            li
              i.circ.cp
              span Ciente potenziale
            li
              i.circ.cu
              span In sospeso

        aside
          ul.shops
            li(v-for="address in mapData" @click="mapDetails(address)")
              i.circ(:class="address.status.toLowerCase()")
              p {{ address.corporate_name }}
              span {{ address.legal_address }}

</template>

<script>
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import ClientOrder from "@/components/ClientOrder.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Maps",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "client-order": ClientOrder,
  },
  data() {
    return {
      center: {},
      markers: [],
      currentPlace: null,
      filters: {
        province: [],
        season_year: null,
        client: null,
        brand: [],
      },
      provinces: [],
      dialogClient: null,
    };
  },
  computed: {
    ...mapGetters("clients", ["mapData"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("orders", ["seasons"]),
  },
  watch: {
    mapData: function (value) {
      this.provinces = new Set(value.map((x) => x.province));
    },
  },
  mounted() {
    this.filterMap();
    this.$store.dispatch("orders/getSeasons");
    this.$store.dispatch("brands/getBrandsList");
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    mapDetails(client) {
      this.dialogClient = client;
    },
    setNewCenter(marker) {
      this.center = marker;
      const client = this.mapData.filter((x) => x.id == marker.id);
      if (client.length > 0) this.mapDetails(client[0]);
    },
    closeDialog() {
      this.dialogClient = null;
    },
    createMap() {
      this.markers = [];
      for (const address of this.mapData) {
        this.markers.push({
          id: address.id,
          lat: Number(address.lat),
          lng: Number(address.lng),
          label: address.corporate_name,
          status: address.status.toLowerCase(),
        });
      }

      this.center = this.markers[0];
    },
    filterMap() {
      if (this.filters.season_year) {
        this.filters.season = this.filters.season_year.substr(0, 2);
        this.filters.year = this.filters.season_year.substr(2);
      } else {
        this.filters.season_year =
          this.filters.season =
          this.filters.year =
            null;
      }
      this.$store.dispatch("clients/getMap", this.filters).then(() => {
        this.createMap();
      });
    },
  },
});
</script>
