<template lang="pug">
  #search
    el-form
      el-button(native-type="submit" icon="el-icon-search")
      el-input(name="q" placeholder="ricerca ..." v-model="q")
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Search",
  data() {
    return {
      q: "",
    };
  },
  created() {
    this.q = this.$route.query.q ?? "";
  },
});
</script>
