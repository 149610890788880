import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ClientState {
  clients: any;
  versions: any;
  map: any;
}

type ClientContext = ActionContext<ClientState, RootState>;

const clients = {
  namespaced: true,
  state: {
    clients: {},
    versions: [],
    map: []
  },
  getters: {
    clientsList: (state: ClientState): any => {
      return state.clients.results;
    },
    clientsCount: (state: ClientState): number => {
      return state.clients.count;
    },
    versionsList: (state: ClientState): any => {
      return state.versions;
    },
    mapData: (state: ClientState): any => {
      return state.map;
    }
  },
  mutations: {
    saveClientsList: (state: ClientState, data: any): void => {
      state.clients = data;
    },
    saveVersions: (state: ClientState, list: any): void => {
      state.versions = list;
    },
    clearClientsMap: (state: ClientState) => {
      state.map = [];
    },
    saveClientsMap: (state: ClientState, list: any): void => {
      state.map = state.map.concat(list.results);
    }
  },
  actions: {
    // Search into the API the list of clients. Use `Authorization` to see that
    // list.
    async getClientsList(context: ClientContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/registry/clients/`;
      if (limit) {
        path += "?limit=" + limit;
      }
      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveClientsList", await response.json());
      });
    },
    // Find a client by its `id`
    async findClient(context: ClientContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/registry/clients/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Get versions list from a client `id`
    async getVersions(context: ClientContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/registry/clients/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Add a data associated to a client
    async addData(context: ClientContext, data: any) {
      const api = context.rootState.api;

      await fetch(`${api}/registry/clients/${data.client}/${data.path}/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id: data.id })
      });
    },
    // Remove a data associated to a client
    async removeData(context: ClientContext, data: any) {
      const api = context.rootState.api;

      await fetch(
        `${api}/registry/clients/${data.client}/${data.path}/${data.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json"
          }
        }
      );
    },
    // Manage data
    async manageData(context: ClientContext, data: any) {
      const type = data.type;
      const res = data.res;
      const client = data.id;
      const path = data.path;

      const dataToDelete = res[type].filter(
        (x: number) => !data.data.includes(x)
      );
      for (const id of dataToDelete) {
        await context.dispatch("removeData", {
          client,
          id,
          type,
          path
        });
      }

      const dataToAdd = data.data.filter((x: number) => !res[type].includes(x));
      for (const id of dataToAdd) {
        await context.dispatch("addData", {
          client,
          id,
          type,
          path
        });
      }
    },
    // Create a new client
    async newClient(context: ClientContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/registry/clients/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );

          await context.dispatch("manageData", {
            id: res.id,
            data: data.commercial,
            res,
            type: "commercial",
            path: "commercials"
          });
          await context.dispatch("manageData", {
            id: res.id,
            data: data.brands,
            res,
            type: "brands",
            path: "brands"
          });
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Edit a client. In `data` we have an object got from `findCompany`
    async editClient(context: ClientContext, data: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;
      let res: any;

      await fetch(`${api}/registry/clients/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );

          await context.dispatch("manageData", {
            id: data.id,
            data: data.commercial,
            res,
            type: "commercial",
            path: "commercials"
          });
          await context.dispatch("manageData", {
            id: res.id,
            data: data.brands,
            res,
            type: "brands",
            path: "brands"
          });
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    },
    // Save new photo for a client using its `type`
    async savePhoto(context: ClientContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res = {};
      const form = new FormData();
      form.append("client", data.client);
      form.append("type", data.type);
      form.append("photo", data.photo);

      await fetch(`${api}/registry/photos/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        },
        body: form
      })
        .then(async response => {
          if (response.status == 201) {
            res = await response.json();
            context.dispatch(
              "toast",
              {
                message: "Foto aggiunta",
                type: "success"
              },
              { root: true }
            );
          } else {
            context.dispatch(
              "toast",
              {
                message: "Foto non aggiunta",
                type: "error"
              },
              { root: true }
            );
          }
        })
        .catch((e: Error) => {
          let error = e.message;
          if (error.indexOf("NetworkError") >= 0) {
            error = "Immagine troppo grande";
          }
          context.dispatch(
            "toast",
            {
              message: "Foto non aggiunta: " + error,
              type: "error"
            },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Delete a photo by `id`
    async removePhoto(context: ClientContext, id: number): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let status = 400;

      await fetch(`${api}/registry/photos/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Foto rimossa",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Foto non rimossa",
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    },
    // Search elements by `query`
    async searchClients(context: ClientContext, query: string) {
      const api = context.rootState.api;

      await fetch(`${api}/registry/clients/search/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ q: query })
      }).then(async response => {
        context.commit("saveClientsList", await response.json());
      });
    },
    // Make the call to print a client as PDF file. Use `id` and `fields` as
    // parameters on GET
    async print(context: ClientContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/registry/clients/${data.id}/print/?`;

      for (const field of data.fields) path += field + "&";

      const pdfError = () => {
        context.dispatch(
          "toast",
          {
            message: "Errore nella generazione del PDF",
            type: "error"
          },
          { root: true }
        );
      };

      fetch(path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      })
        .then(async r => ({
          filename: `client-${data.id}.pdf`,
          blob: await r.blob(),
          headers: r.headers,
          status: r.status
        }))
        .then(obj => {
          if (obj.status != 200) {
            pdfError();
            return;
          }
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch(() => {
          pdfError();
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    },
    // Get the map of the clients addresses
    async getMap(context: ClientContext, filters: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let next = 1;
      let offset = 0;
      let path = `${api}/registry/clients/maps/?limit=1000`;

      if (filters["province"]) {
        for (const province of filters.province) {
          path += `&province=${province}`;
        }
      }

      if (filters["brand"]) {
        for (const brand of filters.brand) {
          path += `&brand=${brand}`;
        }
      }

      if (filters["client"]) {
        path += `&client=${filters.client}`;
      }

      if (filters["season"] && filters["year"]) {
        path += `&season=${filters.season}&year=${filters.year}`;
      }

      context.commit("clearClientsMap");

      while (next) {
        await fetch(`${path}&offset=${offset}`, {
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json"
          }
        }).then(async response => {
          const data = await response.json();
          context.commit("saveClientsMap", data);
          next = data.next;
          offset += data.results.length;
        });
      }

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Make request to get the image with authorization
    getImage(context: ClientContext, url: string) {
      url = url.replace("http:", "https:");

      return fetch(url, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      })
        .then(response => response.blob())
        .then(
          blob =>
            new Promise(callback => {
              const reader = new FileReader();
              reader.onload = function() {
                callback(this.result);
              };
              reader.readAsDataURL(blob);
            })
        );
    },
    async filterClients(context: ClientContext, filtersList: any) {
      const api = context.rootState.api;

      await fetch(`${api}/registry/clients/filter/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-type": "application/json"
        },
        body: JSON.stringify({ ...filtersList })
      })
        .then(async response => {
          const result = await response.json();
          if (response.status == 200) {
            context.commit("saveClientsList", result);
          } else {
            await context.dispatch(
              "toast",
              { details: result, type: "error" },
              { root: true }
            );
          }
        })
        .catch(e => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    }
  }
};

export default clients;
