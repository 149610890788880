
import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "NotificationsAside",
  data() {
    return { notifications: [] };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("tasks", ["tasksList"]),
    ...mapGetters("news", ["newsList"])
  },
  async created() {
    await this.$store.dispatch("news/getNewsList");
    await this.$store.dispatch("tasks/search", { is_open: true });

    this.mergeNotifications();
  },
  methods: {
    mergeNotifications() {
      for (let task of this.tasksList) {
        task.notification_type = "task";
      }
      for (let news of this.newsList) {
        news.modified_at = news.modified;
        news.notification_type = "news";
      }

      let array = this.tasksList.concat(this.newsList);

      array.sort((a: any, b: any) => a.modified_at < b.modified_at);
      this.notifications = array;
    }
  }
});
