
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Settings",
  data() {
    return {
      profileCopies: [{ calendar_iframe: "", id: 0 }],
      hasErrors: [0],
    };
  },
  mounted() {
    this.getProfilesList(1000).then(() => {
      this.profileCopies = JSON.parse(JSON.stringify(this.profilesList));
    });
    this.hasErrors = [];
  },
  computed: {
    ...mapGetters("profiles", ["profilesList"]),
  },
  methods: {
    save() {
      for (const i in this.profileCopies) {
        this.hasErrors = [];
        if (
          this.profileCopies[i].calendar_iframe !=
          this.profilesList[i].calendar_iframe
        ) {
          this.updateProfile({
            profile: this.profileCopies[i],
            fields: ["calendar_iframe"],
          }).then((response) => {
            const i = this.hasErrors.indexOf(response.data);
            if (response.status == 200) {
              if (i >= 0) this.hasErrors.splice(i, 1);
            } else {
              if (i < 0) this.hasErrors.push(response.data);
            }
          });
        }
      }
    },
    ...mapActions("profiles", ["getProfilesList", "updateProfile"]),
  },
});
