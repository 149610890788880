import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface AuthState {
  user: string | null;
  token: string | null;
  refresh: string | null;
  waitForOtp: boolean;
  ephemeralToken: string;
}

type AuthContext = ActionContext<AuthState, RootState>;

const auth = {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem("access_token") || null,
    refresh: localStorage.getItem("refresh_token") || null,
    waitForOtp: false
  },
  getters: {
    accessToken: (state: AuthState): string | null => {
      return state.token;
    },
    isLogged: (state: AuthState): boolean => {
      return state.token != null;
    },
    // eslint-disable-next-line
    me: (state: AuthState): any => {
      return state.user;
    },
    isOTPSent: (state: AuthState): boolean => {
      return state.waitForOtp;
    }
  },
  mutations: {
    saveAccessToken: (state: AuthState, tokens: any): void => {
      localStorage.setItem("access_token", tokens.access);
      state.token = tokens.access;

      if (state.refresh != null) {
        state.refresh = tokens.refresh;
        localStorage.setItem("refresh_token", tokens.refresh);
      }
    },
    logout: (state: AuthState): void => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      state.token = state.refresh = null;
    },
    // Save user's informations from the endpoint `/me`, like its name, phone
    // and role
    // eslint-disable-next-line
    saveUserInfo: (state: AuthState, data: any): void => {
      state.user = data;
    },
    // Save OTP status that has been sent to the email
    otpSent: (state: AuthState): void => {
      state.waitForOtp = true;
    },
    saveEphemeralToken: (state: AuthState, token: string): void => {
      state.ephemeralToken = token;
    },
    // When called it'll save the refresh token after the login.
    // When `state.refresh` is not null it can be overrided by `saveAccessToken`
    // mutation.
    wantKeepAccess: (state: AuthState): void => {
      state.refresh = "";
    }
  },
  actions: {
    // Check if a logged user has a valid access_token. It could be expired
    // so, in that case, if the user wants to keep the access, `access_token`
    // must be regerated.
    // Returns the status of the call to `{{base}}/auth/token/verify/`
    async verifyAccess(context: AuthContext): Promise<number | null> {
      const api = context.rootState.api;
      let result = 204;

      await fetch(`${api}/auth/token/verify/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: context.getters.accessToken })
      }).then(async response => {
        result = response.status;
      });

      if (result != 200 && !context.state.refresh) {
        context.dispatch("logout");
      }

      return result;
    },
    // If called checks if a refresh token is defined and so refresh a JWT token
    // and save the new one.
    // If a new access token is not made 'cause the bad refresh token, make the
    // logout.
    // Returns the status of the call to `{{base}}/auth/token/verify/`. If it
    // does not make the call, returns 400
    async refreshToken(context: AuthContext): Promise<number | null> {
      const api = context.rootState.api;
      let status = 400;

      if (context.state.refresh) {
        await fetch(`${api}/auth/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ refresh: context.state.refresh })
        })
          .then(async response => {
            status = response.status;
            if (status == 200) {
              const data = await response.json();
              data["refresh"] = context.state.refresh;

              context.commit("saveAccessToken", data);
            }
          })
          .catch(e => {
            console.log(e);
            status = 500;
          });
      }

      if (status != 200) {
        context.commit("logout");
      }

      return status;
    },
    // Make the login using `credentials`.
    // It returns the response in JSON format
    // eslint-disable-next-line
    async login(context: AuthContext, credentials: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;
      let res_data;

      await fetch(`${api}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(async response => {
          res_data = await response.json();
          res_status = response.status;
          if (res_status == 200 && res_data) {
            if (credentials["keep_access"]) {
              context.commit("wantKeepAccess");
            }

            if (res_data["access"]) {
              context.commit("saveAccessToken", res_data);
            } else {
              context.commit("otpSent");
              context.commit("saveEphemeralToken", res_data["ephemeral_token"]);
            }
          } else {
            if (res_data.error) {
              res_data = res_data.error;
            }
          }
        })
        .catch(e => {
          res_status = e.status;
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return {
        status: res_status,
        data: res_data
      };
    },
    // The second step of the login. Send the token and the code sent by email
    async login2fa(context: AuthContext, code: string): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res_status = -1;
      let res_data;

      const credentials = {
        ephemeral_token: context.state.ephemeralToken,
        code: code
      };

      await fetch(`${api}/auth/login/code/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(async response => {
          res_data = await response.json();
          res_status = response.status;
          if (res_status == 200) {
            context.commit("saveAccessToken", res_data);
          }
        })
        .catch(e => {
          res_status = e.status;
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return {
        status: res_status,
        data: res_data
      };
    },
    // Make a signup request and then returns a dictionary with response
    // status and response data
    // eslint-disable-next-line
    async signup(context: AuthContext, credentials: object): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      const res = { status: -1, data: null };

      await fetch(`${api}/auth/registration/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials)
      })
        .then(async response => {
          res.data = await response.json();
          res.status = response.status;
        })
        .catch(e => {
          res.status = e.status;
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    logout(context: AuthContext): void {
      context.commit("logout");
    },
    // Search info for current authed user
    async findMe(context: AuthContext) {
      const response = await context.dispatch("profiles/findProfile", "me", {
        root: true
      });
      context.commit("saveUserInfo", response.data);
    }
  }
};

export default auth;
