
import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Orders",
  components: {
    "b-header": Header,
    "blue-top": BlueTop
  },
  data() {
    return {
      transactionTypes: {
        UP: "Carico campionario",
        RE: "Reso al mandante",
        SC: "Vendita cliente",
        RC: "Reso cliente",
        UC: "Carico al venditore",
        RR: "Reso del venditore",
        FA: "Capi omaggio",
        PA: "Capi personali"
      }
    };
  },
  created() {
    this.$store.dispatch("tradefairs/getTradefairsList");
  },
  computed: {
    ...mapGetters("tradefairs", ["tradefairsList"])
  },
  methods: {
    editRow(id: number) {
      window.location.href = "/campionario/" + id;
    },
    newTradefair() {
      window.location.href = "/campionario/nuovo";
    }
  }
});
