
import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "OrdersEdit",
  props: ["id"],
  components: {
    "b-header": Header,
    "blue-top": BlueTop
  },
  data() {
    return {
      isNew: false,
      is_queen: process.env.VUE_APP_IS_QUEEN,
      form: {
        total_sum: 0,
        man_total: 0,
        woman_total: 0,
        delivered_import: 0,
        date: "",
        year: "",
        actual_delivery: "",
        client: null,
        company_line: null,
        commercial: null,
        payment_method: null,
        commercial_condition: null,
        vat: 22
      },
      perc: {
        commission_agency: 0,
        commission_commercial: 0,
        commission_commercial_head: 0
      },
      lines: [{ label: "", company: null }],
      commission_agency: 0,
      commission_commercial: 0,
      commission_commercial_head: 0,
      net_import: 0,
      gross_import: 0,
      rules: {
        client: [{ required: true, message: "Campo obbligatorio" }],
        company_line: [{ required: true, message: "Campo obbligatorio" }],
        date: [{ required: true, message: "Campo obbligatorio" }],
        actual_delivery: [{ required: true, message: "Campo obbligatorio" }],
        commercial: [{ required: true, message: "Campo obbligatorio" }],
        season: [{ required: true, message: "Campo obbligatorio" }],
        year: [{ required: true, message: "Campo obbligatorio" }],
        payment_method: [{ required: true, message: "Campo obbligatorio" }],
        number_of_pieces: [{ required: true, message: "Campo obbligatorio" }],
        man_total: [{ required: true, message: "Campo obbligatorio" }],
        woman_total: [{ required: true, message: "Campo obbligatorio" }],
        vat: [{ required: true, message: "Campo obbligatorio" }]
      },
      availableCommercials: []
    };
  },
  computed: {
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("companies", ["companiesList"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters("orders", ["paymentMethods"]),
    ...mapGetters("commercialconditions", ["commercialConditionsList"])
  },
  async created() {
    await this.$store.dispatch("clients/getClientsList");
    await this.$store.dispatch("companies/getCompaniesList").then(() => {
      this.lines = [];
      for (const company of this.companiesList) {
        for (const line of company.lines) {
          this.lines.push({
            ...line,
            label:
              company.corporate_name +
              " - " +
              line.brand.name +
              " - " +
              line.brand_trend.name,
            company
          });
        }
      }
    });
    await this.$store.dispatch("auth/findMe");
    if (this.me && this.me.role == 1) {
      await this.$store.dispatch("users/getUsersList");
    }
    await this.$store.dispatch("orders/getPaymentMethods");
    await this.$store.dispatch(
      "commercialconditions/getCommercialConditionsList"
    );
    if (this.$props.id != "nuovo") {
      await this.findOrder(this.id)
        .then(response => {
          if (response.status == 200) {
            this.$set(this.$data, "form", response.data);
            this.form.client = response.data.client.id;
            this.form.company_line = response.data.company_line
              ? response.data.company_line.id
              : null;
            this.form.payment_method = response.data.payment_method.id;
            this.form.commercial_condition = response.data.commercial_condition;
            this.form.commercial = response.data.commercial.id;
            this.form.year = response.data.year + "-01-01";
            this.checkAvailableCommercials();
          } else {
            window.location.href = "/ordini";
          }
        })
        .catch(() => {
          window.location.href = "/ordini";
        });
    } else {
      this.isNew = true;
    }
  },
  methods: {
    resetDialog() {
      window.location.href = "/ordini";
    },
    checkAvailableCommercials() {
      if (this.me && this.me.role == 2) return;
      const clientId = this.form.client;
      const companyLine = this.form.company_line;
      if (!(clientId && companyLine)) {
        return;
      }

      const client = this.clientsList.filter((x: any) => x.id == clientId)[0];
      const company_line: any = this.lines.filter(
        (x: any) => x.id == companyLine
      )[0];

      const commercialIds = client.commercial.filter(
        (x: any) => x == company_line.commercial
      );

      this.availableCommercials = this.usersList.filter((x: any) =>
        commercialIds.includes(x.id)
      );

      this.perc.commission_agency = company_line.commission_agency;
      this.perc.commission_commercial = company_line.commission_commercial;
      this.perc.commission_commercial_head =
        company_line.company.commission_commercial_head;
    },
    checkCommercialCommission() {
      const user = this.usersList.filter(
        (x: any) => x.id == this.form.commercial
      )[0];

      if (user.commission != null) {
        this.perc.commission_commercial = user.commission;
      }
    },
    calcCommission() {
      const total_sum =
        Number(this.form.woman_total) + Number(this.form.man_total);
      if (!total_sum) {
        return;
      }

      let commission_agency = this.perc.commission_agency ?? 0;
      let commission_commercial = this.perc.commission_commercial ?? 0;
      let commission_commercial_head =
        this.perc.commission_commercial_head ?? 0;

      if (this.form.vat) {
        this.gross_import =
          Number(this.form.total_sum) +
          Number((this.form.total_sum * this.form.vat) / 100);
      }

      commission_agency = (total_sum * commission_agency) / 100;
      commission_commercial = (commission_agency * commission_commercial) / 100;
      commission_commercial_head =
        (commission_agency * commission_commercial_head) / 100;

      this.commission_agency = Number(commission_agency.toFixed(2));
      this.commission_commercial = Number(commission_commercial.toFixed(2));
      this.commission_commercial_head = Number(
        commission_commercial_head.toFixed(2)
      );
      this.net_import = Number((total_sum - commission_agency).toFixed(2));
    },
    async onSubmit() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        this.form.date = moment(this.form.date).format("YYYY-MM-DD");
        this.form.actual_delivery = moment(this.form.actual_delivery).format(
          "YYYY-MM-DD"
        );
        this.form.year = moment(this.form.year).format("YYYY");

        interface OrderForm {
          man_total: number;
          woman_total: number;
          date: string;
          year: string;
          actual_delivery: string;
          commission_agency?: number;
          commission_commercial?: number;
        }

        const data: OrderForm = {
          ...this.form
        };

        if (this.perc.commission_agency)
          data["commission_agency"] = this.perc.commission_agency;

        if (this.perc.commission_commercial)
          data["commission_commercial"] = this.perc.commission_commercial;

        if (this.isNew) {
          this.newOrder(data).then(async response => {
            if (response.id) {
              window.location.href = "/ordini";
            }
          });
        } else {
          this.editOrder(data).then(async response => {
            if (response.id) {
              window.location.href = "/ordini";
            }
          });
        }
      } else {
        return false;
      }
    },
    ...mapActions("orders", ["newOrder", "editOrder", "findOrder"])
  }
});
