import { ActionContext } from "vuex";
import { RootState } from "../state";
import clients from "./clients";

export interface OrderState {
  orders: any;
  paymentMethods: any;
  seasons: any;
  paymentsVersions: any;
  versions: any;
  count: number;
  map: any;
}

type OrderContext = ActionContext<OrderState, RootState>;

const orders = {
  namespaced: true,
  state: {
    orders: {},
    paymentMethods: [],
    seasons: [],
    paymentsVersions: [],
    versions: [],
    map: [],
    count: 0,
  },
  getters: {
    ordersList: (state: OrderState): any => {
      return state.orders.results;
    },
    ordersCount: (state: OrderState): number => {
      return state.orders.count;
    },
    paymentMethods: (state: OrderState): any => {
      return state.paymentMethods.results;
    },
    seasons: (state: OrderState): any => {
      return state.seasons.results;
    },
    paymentsVersionsList: (state: OrderState): any => {
      return state.paymentsVersions;
    },
    versionsList: (state: OrderState): any => {
      return state.versions;
    },
    count: (state: OrderState): number => {
      return state.count;
    },
    mapData: (state: OrderState): any => {
      return state.map;
    },
  },
  mutations: {
    saveOrdersList: (state: OrderState, list: any): void => {
      state.orders = list;
    },
    savePaymentMethods: (state: OrderState, list: any): void => {
      state.paymentMethods = list;
    },
    saveSeasons: (state: OrderState, list: any): void => {
      state.seasons = list;
    },
    extendsPaymentMethodsList: (state: OrderState, obj: any): void => {
      state.paymentMethods.results = state.paymentMethods.results.concat(obj);
    },
    saveVersions: (state: OrderState, list: any): void => {
      state.versions = list;
    },
    savePaymentsVersions: (state: OrderState, list: any): void => {
      state.paymentsVersions = list;
    },
    saveOrdersCount: (state: OrderState, count: number): void => {
      state.count = count;
    },
    clearOrdersMap: (state: OrderState): void => {
      state.map = [];
    },
    saveOrdersMap: (state: OrderState, list: any): void => {
      state.map = state.map.concat(list.results);
    },
  },
  actions: {
    async filterOrders(context: OrderContext, filtersList: any) {
      const api = context.rootState.api;

      try {
        const response = await fetch(`${api}/orders/filter/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify({ ...filtersList }),
        });

        const result = await response.json();

        if (response.ok) {
          context.commit("saveOrdersList", result);
        } else {
          console.error("Errore dal server:", result);
          await context.dispatch(
            "toast",
            { details: result, type: "error" },
            { root: true }
          );
        }
      } catch (e: any) {
        console.error("Errore nella richiesta:", e);
        context.dispatch(
          "toast",
          { message: e.message, type: "error" },
          { root: true }
        );
      }
    },

    // Search into the API the list of orders. Use `Authorization` to see that
    // list.
    async getOrdersList(context: OrderContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/orders/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("saveOrdersList", await response.json());
      });
    },
    // Find an order by its `id`
    async findOrder(context: OrderContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404,
      };

      await fetch(`${api}/orders/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        result = {
          data: await response.json(),
          status: response.status,
        };
      });

      return result;
    },
    // Get list of available payment methods
    async getPaymentMethods(context: OrderContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/orders/payment-methods/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("savePaymentMethods", await response.json());
      });
    },
    // Add new payment
    async newPayment(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/orders/${data.order}/payments/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        const data = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: data["detail"] ?? data,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Edit a payment
    async editPayment(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/orders/${data.order}/payments/${data.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Get versions list from an order `id`
    async getVersions(context: OrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/orders/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Get versions payment list from an order `id`
    async getPaymentsVersions(context: OrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/orders/${id}/payments/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("savePaymentsVersions", result);
        });
    },
    // Add new payment-method
    async newPaymentMethod(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/payment-methods/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
          context.commit("extendsPaymentMethodsList", await response.json());
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Search a payment-method into the array and delete it
    async deletePaymentMethod(context: OrderContext, index: number) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      const id = context.state.paymentMethods.results[index].id;

      await fetch(`${api}/orders/payment-methods/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Metodo di pagamento rimosso",
              type: "success",
            },
            { root: true }
          );
          context.state.paymentMethods.results.splice(index, 1);
        } else {
          context.dispatch(
            "toast",
            {
              message: "Metodo di pagamento non rimosso",
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Edit a payment-method
    async editPaymentMethod(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/payment-methods/${data.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifiche non salvate",
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Create a new order
    async newOrder(context: OrderContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/orders/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Edit an order. In `data` we have an object got from `findOrder`
    async editOrder(context: OrderContext, data: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;
      let res: any;

      await fetch(`${api}/orders/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    },
    // Make the call to print an order as PDF file. Use `id` and `fields` as
    // parameters on GET
    async print(context: OrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/orders/${data.id}/print/?`;

      for (const field of data.fields) path += field + "&";

      const pdfError = () => {
        context.dispatch(
          "toast",
          {
            message: "Errore nella generazione del PDF",
            type: "error",
          },
          { root: true }
        );
      };

      fetch(path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      })
        .then(async (r) => ({
          filename: `order-${data.id}.pdf`,
          blob: await r.blob(),
          headers: r.headers,
          status: r.status,
        }))
        .then((obj) => {
          if (obj.status != 200) {
            pdfError();
            return;
          }
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch(() => {
          pdfError();
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    },
    // Get list of seasons
    async getSeasons(context: OrderContext) {
      const api = context.rootState.api;

      await fetch(`${api}/orders/seasons/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("saveSeasons", await response.json());
      });
    },
    // Search orders by its client
    async searchOrders(context: OrderContext, client: number): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];
      let next = 1;
      let offset = 0;

      const path = `${api}/orders/search/?limit=20`;
      while (next) {
        await fetch(`${path}&offset=${offset}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ client }),
        }).then(async (response) => {
          const data = await response.json();
          result = result.concat(data.results);
          next = data.next;
          offset += data.results.length;
        });
      }

      return result;
    },
    async getMap(context: OrderContext, filters: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let next = true;
      let offset = 0;
      let path = `${api}/orders/maps/?limit=1000`;

      // Aggiungi i filtri all'URL
      if (filters["date_from"]) {
        path += `&date_from=${filters.date_from}`;
      }

      if (filters["date_to"]) {
        path += `&date_to=${filters.date_to}`;
      }

      if (filters["clients"]) {
        for (const client of filters.clients) {
          path += `&clients=${client}`; // Corretto da 'clients' a 'client'
        }
      }

      if (filters["commercials"]) {
        for (const commercial of filters.commercials) {
          path += `&commercials=${commercial}`;
        }
      }

      if (
        filters["is_completed"] !== undefined &&
        filters["is_completed"] !== null
      ) {
        path += `&is_completed=${filters.is_completed}`;
      }

      if (filters["brands"]) {
        for (const brand of filters.brands) {
          path += `&brands=${brand}`;
        }
      }

      if (filters["season"]) {
        path += `&season=${filters.season}`;
      }

      if (filters["year"]) {
        path += `&year=${filters.year}`;
      }

      // Aggiungi altri filtri se necessario

      context.commit("clearOrdersMap");

      while (next) {
        const response = await fetch(`${path}&offset=${offset}`, {
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        context.commit("saveOrdersMap", data);
        console.log("Data from API da getMap:", data);
        next = data.next;
        offset += data.results.length;
      }

      context.commit("changeLoadingStatus", false, { root: true });
    },
  },
};

export default orders;

// import { ActionContext } from "vuex";
// import { RootState } from "../state";
// import { Order } from "@/interfaces/order.interface";

// export interface OrderState {
//   orders: {
//     results: Order[];
//     count: number;
//   };
//   paymentMethods: any;
//   seasons: any;
//   paymentsVersions: any;
//   versions: any;
//   count: number;
//   filteredOrdersList: Order[];
// }

// type OrderContext = ActionContext<OrderState, RootState>;

// const orders = {
//   namespaced: true,
//   state: {
//     orders: {
//       results: [],
//       count: 0,
//     },
//     paymentMethods: [],
//     seasons: [],
//     paymentsVersions: [],
//     versions: [],
//     count: 0,
//     filteredOrdersList: [],
//   },
//   getters: {
//     mapData: (state: OrderState): any => {
//       if (Array.isArray(state.orders)) {
//         return state.orders;
//       }
//       return state.orders.results || [];
//     },
//     ordersList: (state: OrderState): any[] => {
//       return state.orders.results || [];
//     },
//     ordersCount: (state: OrderState): number => {
//       return state.orders.count;
//     },
//     paymentMethods: (state: OrderState): any => {
//       return state.paymentMethods.results;
//     },
//     seasons: (state: OrderState): any => {
//       return state.seasons.results;
//     },
//     paymentsVersionsList: (state: OrderState): any => {
//       return state.paymentsVersions;
//     },
//     versionsList: (state: OrderState): any => {
//       return state.versions;
//     },
//     count: (state: OrderState): number => {
//       return state.count;
//     },
//   },
//   mutations: {
//     saveOrdersList: (state: OrderState, list: any): void => {
//       if (Array.isArray(list)) {
//         state.orders = {
//           results: list,
//           count: list.length,
//         };
//       } else if (list.results && typeof list.count === "number") {
//         // Se list è già un oggetto con results e count, assegnalo direttamente
//         state.orders = list;
//       } else {
//         // Gestisci il caso in cui list non sia né un array né un oggetto con results e count
//         state.orders = {
//           results: [],
//           count: 0,
//         };
//       }
//     },
//     savePaymentMethods: (state: OrderState, list: any): void => {
//       state.paymentMethods = list;
//     },
//     saveSeasons: (state: OrderState, list: any): void => {
//       state.seasons = list;
//     },
//     extendsPaymentMethodsList: (state: OrderState, obj: any): void => {
//       state.paymentMethods.results = state.paymentMethods.results.concat(obj);
//     },
//     saveVersions: (state: OrderState, list: any): void => {
//       state.versions = list;
//     },
//     savePaymentsVersions: (state: OrderState, list: any): void => {
//       state.paymentsVersions = list;
//     },
//     saveOrdersCount: (state: OrderState, count: number): void => {
//       state.count = count;
//     },
//     saveFilteredOrdersList: (state: OrderState, filteredOrders: Order[]) => {
//       state.filteredOrdersList = filteredOrders;
//     },
//   },
//   actions: {
//     // Search into the API the list of orders. Use `Authorization` to see that
//     // list.
//     async filterOrders(context: OrderContext, filtersList: any) {
//       const api = context.rootState.api;

//       try {
//         const response = await fetch(`${api}/orders/filter/`, {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//             "Content-type": "application/json",
//           },
//           body: JSON.stringify(filtersList),
//         });

//         const result = await response.json();

//         if (response.ok) {
//           context.commit("saveOrdersList", result);
//         } else {
//           console.error("Errore dal server:", result);
//           await context.dispatch(
//             "toast",
//             { details: result, type: "error" },
//             { root: true }
//           );
//         }
//       } catch (e: any) {
//         console.error("Errore nella richiesta:", e);
//         context.dispatch(
//           "toast",
//           { message: e.message, type: "error" },
//           { root: true }
//         );
//       }
//     },

//     async getMap(context: OrderContext) {
//       const api = context.rootState.api;

//       try {
//         const response = await fetch(`${api}/orders/`, {
//           headers: {
//             Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           },
//         });

//         if (!response.ok) {
//           throw new Error("Errore nel recupero degli ordini");
//         }

//         const data = await response.json();
//         console.log("Data from API:", data);
//         context.commit("saveOrdersList", data);
//       } catch (error) {
//         console.error(error);
//         context.dispatch(
//           "toast",
//           { message: "Errore nel recupero degli ordini", type: "error" },
//           { root: true }
//         );
//       }
//     },

//     async getOrdersList(context: OrderContext, limit: number | undefined) {
//       const api = context.rootState.api;

//       let path = `${api}/orders/`;
//       if (limit) {
//         path += "?limit=" + limit;
//       }
//       try {
//         const response = await fetch(path, {
//           headers: {
//             Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           },
//         });

//         if (!response.ok) {
//           throw new Error("Errore nel recupero degli ordini");
//         }

//         const data = await response.json();
//         context.commit("saveOrdersList", data);
//       } catch (error) {
//         console.error(error);
//         context.dispatch(
//           "toast",
//           { message: "Errore nel recupero degli ordini", type: "error" },
//           { root: true }
//         );
//       }
//     },

//     // Find an order by its `id`
//     async findOrder(context: OrderContext, id: number): Promise<any> {
//       const api = context.rootState.api;
//       let result = {
//         data: {},
//         status: 404,
//       };

//       await fetch(`${api}/orders/${id}/`, {
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//         },
//       }).then(async (response) => {
//         result = {
//           data: await response.json(),
//           status: response.status,
//         };
//       });

//       return result;
//     },
//     // Get list of available payment methods
//     async getPaymentMethods(context: OrderContext, limit: number | undefined) {
//       const api = context.rootState.api;

//       let path = `${api}/orders/payment-methods/`;
//       if (limit) {
//         path += "?limit=" + limit;
//       }

//       await fetch(path, {
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//         },
//       }).then(async (response) => {
//         context.commit("savePaymentMethods", await response.json());
//       });
//     },
//     // Add new payment
//     async newPayment(context: OrderContext, data: any): Promise<number> {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });
//       let status = 400;

//       await fetch(`${api}/orders/${data.order}/payments/`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         status = response.status;
//         const data = await response.json();
//         if (status == 201) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: data["detail"] ?? data,
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });
//       return status;
//     },
//     // Edit a payment
//     async editPayment(context: OrderContext, data: any): Promise<number> {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });
//       let status = 400;

//       await fetch(`${api}/orders/${data.order}/payments/${data.id}/`, {
//         method: "PATCH",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         status = response.status;
//         if (status == 200) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: await response.json(),
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });
//       return status;
//     },
//     // Get versions list from an order `id`
//     async getVersions(context: OrderContext, id: number) {
//       const api = context.rootState.api;
//       const path = `${api}/orders/${id}/versions/`;
//       context
//         .dispatch("revisions/getVersions", path, { root: true })
//         .then((result: any) => {
//           context.commit("saveVersions", result);
//         });
//     },
//     // Get versions payment list from an order `id`
//     async getPaymentsVersions(context: OrderContext, id: number) {
//       const api = context.rootState.api;
//       const path = `${api}/orders/${id}/payments/versions/`;
//       context
//         .dispatch("revisions/getVersions", path, { root: true })
//         .then((result: any) => {
//           context.commit("savePaymentsVersions", result);
//         });
//     },
//     // Add new payment-method
//     async newPaymentMethod(context: OrderContext, data: any) {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });

//       await fetch(`${api}/orders/payment-methods/`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         const status = response.status;
//         if (status == 201) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//           context.commit("extendsPaymentMethodsList", await response.json());
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: await response.json(),
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });
//     },
//     // Search a payment-method into the array and delete it
//     async deletePaymentMethod(context: OrderContext, index: number) {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });

//       const id = context.state.paymentMethods.results[index].id;

//       await fetch(`${api}/orders/payment-methods/${id}`, {
//         method: "DELETE",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//         },
//       }).then(async (response) => {
//         const status = response.status;
//         if (status == 204) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Metodo di pagamento rimosso",
//               type: "success",
//             },
//             { root: true }
//           );
//           context.state.paymentMethods.results.splice(index, 1);
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: "Metodo di pagamento non rimosso",
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });
//     },
//     // Edit a payment-method
//     async editPaymentMethod(context: OrderContext, data: any) {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });

//       await fetch(`${api}/orders/payment-methods/${data.id}`, {
//         method: "PATCH",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         const status = response.status;
//         if (status == 200) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche non salvate",
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });
//     },
//     // Create a new order
//     async newOrder(context: OrderContext, data: any): Promise<any> {
//       const api = context.rootState.api;

//       context.commit("changeLoadingStatus", true, { root: true });

//       let res: any;

//       await fetch(`${api}/orders/`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         const status = response.status;
//         res = await response.json();
//         if (status == 201) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: res,
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });

//       return res;
//     },
//     // Edit an order. In `data` we have an object got from `findOrder`
//     async editOrder(context: OrderContext, data: any): Promise<number> {
//       const api = context.rootState.api;

//       context.commit("changeLoadingStatus", true, { root: true });
//       let status = 400;
//       let res: any;

//       await fetch(`${api}/orders/${data.id}/`, {
//         method: "PUT",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }).then(async (response) => {
//         status = response.status;
//         res = await response.json();
//         if (status == 200) {
//           context.dispatch(
//             "toast",
//             {
//               message: "Modifiche salvate",
//               type: "success",
//             },
//             { root: true }
//           );
//         } else {
//           context.dispatch(
//             "toast",
//             {
//               message: res,
//               type: "error",
//             },
//             { root: true }
//           );
//         }
//       });

//       context.commit("changeLoadingStatus", false, { root: true });

//       return status;
//     },

//     // Make the call to print an order as PDF file. Use `id` and `fields` as
//     // parameters on GET
//     async print(context: OrderContext, data: any) {
//       const api = context.rootState.api;
//       context.commit("changeLoadingStatus", true, { root: true });
//       let path = `${api}/orders/${data.id}/print/?`;

//       for (const field of data.fields) path += field + "&";

//       const pdfError = () => {
//         context.dispatch(
//           "toast",
//           {
//             message: "Errore nella generazione del PDF",
//             type: "error",
//           },
//           { root: true }
//         );
//       };

//       fetch(path, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//         },
//       })
//         .then(async (r) => ({
//           filename: `order-${data.id}.pdf`,
//           blob: await r.blob(),
//           headers: r.headers,
//           status: r.status,
//         }))
//         .then((obj) => {
//           if (obj.status != 200) {
//             pdfError();
//             return;
//           }
//           const newBlob = new Blob([obj.blob], { type: "application/pdf" });

//           if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//             window.navigator.msSaveOrOpenBlob(newBlob);
//           } else {
//             // For other browsers: create a link pointing to the ObjectURL containing the blob.
//             const objUrl = window.URL.createObjectURL(newBlob);

//             const link = document.createElement("a");
//             link.href = objUrl;
//             link.download = obj.filename;
//             link.click();

//             // For Firefox it is necessary to delay revoking the ObjectURL.
//             setTimeout(() => {
//               window.URL.revokeObjectURL(objUrl);
//             }, 250);
//           }
//         })
//         .catch(() => {
//           pdfError();
//         })
//         .finally(() => {
//           context.commit("changeLoadingStatus", false, { root: true });
//         });
//     },
//     // Get list of seasons
//     async getSeasons(context: OrderContext) {
//       const api = context.rootState.api;

//       await fetch(`${api}/orders/seasons/`, {
//         headers: {
//           Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//         },
//       }).then(async (response) => {
//         context.commit("saveSeasons", await response.json());
//       });
//     },
//     // Search orders by its client
//     async searchOrders(context: OrderContext, client: number): Promise<any> {
//       const api = context.rootState.api;
//       let result: any = [];
//       let next = 1;
//       let offset = 0;

//       const path = `${api}/orders/search/?limit=20`;
//       while (next) {
//         await fetch(`${path}&offset=${offset}`, {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ client }),
//         }).then(async (response) => {
//           const data = await response.json();
//           result = result.concat(data.results);
//           next = data.next;
//           offset += data.results.length;
//         });
//       }

//       return result;
//     },
//   },
// };

// export default orders;
