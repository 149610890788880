import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

export interface ExpectedOrderState {
  orders: any;
  versions: any;
  currentCommercial: number;
  currentYear: number;
  currentSeason: number;
}

type ExpectedOrderContext = ActionContext<ExpectedOrderState, RootState>;

const agencies = {
  namespaced: true,
  state: {
    orders: [],
    versions: [],
    currentCommercial: 0,
    currentSeason: 0,
    currentYear: 0,
  },
  getters: {
    expectedOrders: (state: ExpectedOrderState): any => {
      return state.orders;
    },
    versionsList: (state: ExpectedOrderState): any => {
      return state.versions;
    },
    commercial: (state: ExpectedOrderState): number => {
      return state.currentCommercial;
    },
    year: (state: ExpectedOrderState): number => {
      return state.currentYear;
    },
    season: (state: ExpectedOrderState): number => {
      return state.currentSeason;
    },
  },
  mutations: {
    saveExpectedOrders: (state: ExpectedOrderState, data: any): void => {
      state.orders = data;
    },
    saveVersions: (state: ExpectedOrderState, list: any): void => {
      state.versions = list;
    },
    saveCurrentData: (state: ExpectedOrderState, data: any): void => {
      state.currentCommercial = data.commercial;
      state.currentSeason = data.season;
      state.currentYear = data.year;
    },
  },
  actions: {
    // Reset order data
    clearExpectedOrders(context: ExpectedOrderContext) {
      context.commit("saveExpectedOrders", []);
    },
    // Search orders by year, commercial and season
    async searchExpectedOrders(context: ExpectedOrderContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      const { commercial, year, season } = data;
      let result = null;

      context.commit("saveCurrentData", { commercial, year, season });

      await fetch(`${api}/targets/orders/search/?limit=1000`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commercial,
          year,
          season,
        }),
      })
        .then(async (response) => {
          result = await response.json();
          if (response.status != 200) {
            context.dispatch("clearExpectedOrders");
          } else {
            context.commit("saveExpectedOrders", result.results);
          }
        })
        .catch(() => {
          context.dispatch("clearExpectedOrder");
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Get versions list from an order `id`
    async getVersions(context: ExpectedOrderContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/targets/orders/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Finalize an expected order, linking a real order, changing the status or
    // just adding a note.
    async finalize(context: ExpectedOrderContext, data: any): Promise<any> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let result;

      await fetch(`${api}/targets/orders/${data.id}/finalize/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data.form),
      })
        .then(async (response) => {
          result = await response.json();
          if (response.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Salvato con successo",
                type: "success",
              },
              { root: true }
            );
            context.dispatch("searchExpectedOrders", {
              commercial: context.getters.commercial,
              season: context.getters.season,
              year: context.getters.year,
            });
          } else {
            context.dispatch(
              "toast",
              {
                message: result,
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
    // Add new expected order
    async newExpectedOrder(context: ExpectedOrderContext, form: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      form.year = context.getters.year;
      form.season = context.getters.season;
      await fetch(`${api}/targets/orders/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then(async (response) => {
          const result = await response.json();
          if (response.status == 201) {
            context.dispatch(
              "toast",
              {
                message: "Ordine creato con successo",
                type: "success",
              },
              { root: true }
            );
            context.dispatch("searchExpectedOrders", {
              commercial: context.getters.commercial,
              season: context.getters.season,
              year: context.getters.year,
            });
          } else {
            context.dispatch(
              "toast",
              {
                message: result,
                type: "error",
              },
              { root: true }
            );
          }
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
  },
};

export default agencies;
