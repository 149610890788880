
import Vue from "vue";

import Header from "@/components/Header.vue";
import PrintRow from "@/components/PrintRow.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "CustomersEdit",
  props: ["id"],
  components: {
    "b-header": Header,
    "print-row": PrintRow,
    "history-table": HistoryTable
  },
  data() {
    var validatePhone = (rule: any, value: any, callback: any) => {
      for (const ch of value) {
        if (ch == "+" || ch == " " || ch == "0") continue;

        if (!Number(ch)) {
          callback(new Error("Non è un numero valido"));
          return;
        }
      }

      callback();
    };

    return {
      printCustomerStatus: false,
      map_key: process.env.VUE_APP_MAPS_KEY,
      addressesAreEquals: false,
      statusDropdown: false,
      typesList: [
        { key: "CA", value: "Cliente attivo" },
        { key: "CC", value: "Cliente chiuso" },
        { key: "CP", value: "Cliente potenziale" },
        { key: "CS", value: "Cliente da non servire" },
        { key: "CI", value: "Cliente inattivo" },
        { key: "CU", value: "Cliente in sospeso" }
      ],
      categories: [
        {
          value: "Uomo",
          icon: "man"
        },
        {
          value: "Donna",
          icon: "woman"
        },
        { value: "Bambino", icon: "baby" },
        {
          value: "Accessori",
          icon: "accessories"
        }
      ],
      showMq: false,
      showWindows: false,
      inputVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      fields: [
        { label: "anagrafica cliente", text: "Anagrafica cliente" },
        { label: "dati", text: "Dati del cliente" },
        { label: "categorie", text: "Categorie" },
        { label: "brands", text: "Brand/Linea" },
        { label: "negozio", text: "Negozio" },
        { label: "indirizzi", text: "Indirizzi" },
        { label: "rating", text: "Rating commerciale e finanziario" },
        { label: "social", text: "Social" },
        { label: "responsabile", text: "Responsabile" },
        { label: "note", text: "Note" }
      ],
      data: {
        id: 0,
        status: "",
        photos: [],
        manager: [
          {
            name: "",
            phone: "",
            cellular: "",
            email: "",
            label: ""
          }
        ],
        categories: [],
        website: "",
        lat: "",
        lng: "",
        city: "",
        province: "",
        postcode: "",
        region: "",
        other_addresses: []
      },
      cacheForm: {},
      interns: [{ photo: "" }],
      outside: [{ photo: "" }],
      other_addresses_options: [
        {
          value: "LE",
          label: "Sede legale"
        },
        {
          value: "ME",
          label: "Logistica"
        },
        {
          value: "ST",
          label: "Punto vendita"
        }
      ],
      isNew: false,
      rules: {
        corporate_name: [
          {
            required: true,
            message: "Inserisci un nome cliente",
            trigger: "blur"
          }
        ],
        sign: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        email: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
          {
            type: "email",
            message: "Email non valida",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        status: [
          { required: true, message: "Seleziona uno stato", trigger: "blur" }
        ]
      },
      myTasks: []
    };
  },
  computed: {
    hasChanged(): boolean {
      const obj1 = JSON.parse(JSON.stringify(this.cacheForm));
      delete obj1.photos;
      const obj2 = JSON.parse(JSON.stringify(this.data));
      delete obj2.photos;
      return JSON.stringify(obj1) !== JSON.stringify(obj2);
    },
    ...mapGetters("auth", ["me"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("clients", ["versionsList"])
  },
  async created() {
    this.$store.dispatch("users/getUsersList");
    this.$store.dispatch("brands/getBrandsList");

    if (this.$props.id != "nuovo") {
      await this.findClient(this.id)
        .then(response => {
          if (response.status == 200) {
            this.$set(this.$data, "data", response.data);
            if (
              Object.prototype.toString.call(this.$data.data.manager) !==
              "[object Array]"
            ) {
              this.$data.data.manager = [this.$data.data.manager];
            }
            this.getVersions(this.data.id);

            this.interns = this.data.photos.filter((x: any) => x.type == "in");
            this.outside = this.data.photos.filter((x: any) => x.type == "out");
            this.$data.addressesAreEquals =
              response.data.legal_address ==
              response.data.merchandise_delivery_address;
            this.getImages();

            this.cacheForm = JSON.parse(JSON.stringify(response.data));
            window.addEventListener("beforeunload", this.handlerClose);
          } else {
            window.location.href = "/clienti";
          }
        })
        .catch(() => {
          window.location.href = "/clienti";
        });
      this.$store
        .dispatch("tasks/search", {
          is_open: true,
          client: this.id
        })
        .then(response => {
          this.$data.myTasks = response;
        });
    } else {
      this.isNew = true;
    }
  },
  methods: {
    deleteAddressRow(index: number) {
      this.$data.data.other_addresses.splice(index, 1);
    },
    newOtherAddress() {
      this.$data.data.other_addresses.push({ address_type: null, address: "" });
    },
    sameAddress(event: any) {
      if (event) {
        this.$set(
          this.$data.data,
          "merchandise_delivery_address",
          this.$data.data.legal_address
        );
      }
      this.$data.addressesAreEquals = event;
    },
    handlerClose(event: any) {
      if (this.hasChanged) {
        event.preventDefault();
        event.returnValue = ""; // Used for Chrome
      }
    },
    clientTypeDescription(status: string): string {
      const table: any = {
        CA: "Cliente attivo",
        CC: "Cliente chiuso",
        CP: "Cliente potenziale",
        CS: "Cliente da non servire",
        CI: "Cliente inattivo",
        CU: "Cliente in sospeso"
      };

      return table[status] || "";
    },
    setStatus(status: string) {
      this.data.status = status;
      this.statusDropdown = false;
    },
    printCustomer() {
      this.printCustomerStatus = !this.printCustomerStatus;
    },
    getImages() {
      for (const image of this.interns) {
        this.getImage(image.photo).then(r => {
          image.photo = r;
        });
      }
      for (const image of this.outside) {
        this.getImage(image.photo).then(r => {
          image.photo = r;
        });
      }
    },
    // eslint-disable-next-line
    newImageOnList(file: any, fileList: any, key: string, type: string) {
      this.savePhoto({
        photo: file.raw,
        client: this.id,
        type
      }).then(async response => {
        const photo = await response;

        fileList.pop();
        if (photo["id"]) {
          await this.getImage(photo.photo).then(r => {
            photo.photo = r;
            if (type == "in") this.interns.push(photo);
            else this.outside.push(photo);
          });
        }
      });
    },
    // eslint-disable-next-line
    newImageOnListInterns(file: any, fileList: any) {
      this.newImageOnList(file, fileList, "interns", "in");
    },
    // eslint-disable-next-line
    newImageOnListOutside(file: any, fileList: any) {
      this.newImageOnList(file, fileList, "outside", "out");
    },
    deleteImage(fileId: number, key: string) {
      this.removePhoto(fileId).then(async response => {
        if ((await response) == 204) {
          const items = this.$data[key];
          for (let i = 0; i < items.length; ++i) {
            if (items[i].id == fileId) {
              items.splice(i, 1);
            }
          }
        }
      });
    },
    deleteImageInterns(fileId: number) {
      this.deleteImage(fileId, "interns");
    },
    deleteImageOutside(fileId: number) {
      this.deleteImage(fileId, "outside");
    },
    setPlaceForOtherAddress(place: any, index: number) {
      this.$set(
        this.data.other_addresses[index],
        "address",
        place.formatted_address
      );
    },
    // eslint-disable-next-line
    getAddress(place: any, field: string) {
      this.$set(this.data, field, place.formatted_address);
    },
    legalAddressMap(place: any) {
      if (place.address_components.length == 7) {
        let newAddressComponents = new Array(8);
        newAddressComponents[0] = "0";

        for (let i = 1; i < 8; i++) {
          newAddressComponents[i] = place.address_components[i - 1];
        }

        place.address_components = newAddressComponents;
      }

      this.getAddress(place, "legal_address");
      this.data.lat = place.geometry.location
        .lat()
        .toString()
        .substr(0, 10);
      this.data.lng = place.geometry.location
        .lng()
        .toString()
        .substr(0, 10);
      this.data.city = place.address_components[3].short_name;
      this.data.province = place.address_components[4].short_name;
      this.data.postcode = place.address_components[7].short_name;
      this.data.region = place.address_components[5].short_name;
    },
    merchandiseDeliveryAddressMap(place: any) {
      this.getAddress(place, "merchandise_delivery_address");
    },
    save() {
      (this.$refs.form as Vue & {
        validate: (cb: (valid: boolean) => void) => void;
      }).validate((valid: boolean) => {
        if (valid) {
          const website = this.data.website;
          if (website != "") {
            if (
              !(website.indexOf("http:") == 0 || website.indexOf("https:") == 0)
            ) {
              this.data.website = "https://" + website;
            }
          }

          if (this.isNew) {
            this.newClient(this.data).then(data => {
              if (data["id"]) {
                setTimeout(() => {
                  window.location.href = "/clienti/" + data.id;
                }, 100);
              }
            });
          } else {
            this.editClient(this.data).then(async response => {
              if ((await response) == 200) {
                // Prevent errors on page leaving
                this.cacheForm = this.data;
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }
            });
          }
        } else {
          this.toast({
            message: "Riempi i campi obbligatori",
            type: "error"
          });
        }
      });
    },
    async closeTask(task: any) {
      const payload: any = {
        ...task,
        commercial: task.commercial.id,
        client: task.client.id,
        is_open: false
      };

      await this.$store.dispatch("tasks/editTask", payload);
    },
    newManagerRow() {
      this.$data.data.manager.push({
        name: "",
        phone: "",
        cellular: "",
        email: "",
        label: ""
      });
    },
    deleteManagerRow(i: number) {
      if (i < 1 || i >= this.$data.data.manager.length) return;

      this.$data.data.manager.splice(i, 1);
    },
    ...mapActions(["toast"]),
    ...mapActions("clients", [
      "findClient",
      "getVersions",
      "newClient",
      "editClient",
      "savePhoto",
      "removePhoto",
      "getImage"
    ])
  }
});
