
import Vue from "vue";

import Header from "@/components/Header.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "News",
  components: {
    "b-header": Header
  },
  computed: {
    ...mapGetters("news", ["newsList"])
  },
  created() {
    this.$store.dispatch("news/getNewsList");
  },
  methods: {
    goback() {
      window.history.back();
    }
  }
});
