
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TasksList",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner
  },
  data() {
    return {
      commercial: "",
      client: "",
      company: "",
      is_open: null,
      myTasks: []
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("companies", ["companiesList"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"])
  },
  created() {
    this.$store.dispatch("clients/getClientsList", 1000);
    this.$store.dispatch("companies/getCompaniesList", 1000);
    this.$store.dispatch("users/getUsersList");
    this.$store.dispatch("tasks/search", {}).then(response => {
      this.$data.myTasks = response;
    });
  },
  methods: {
    filter() {
      const filters: any = {};
      if (this.commercial) filters["commercial"] = this.commercial;
      if (this.client) filters["client"] = this.client;
      if (this.company) filters["company"] = this.company;

      if (this.is_open === "") this.is_open = null;
      else if (this.is_open != null) filters["is_open"] = this.is_open;
      this.$store.dispatch("tasks/search", { ...filters }).then(response => {
        this.$data.myTasks = response;
      });
    },
    async changeTaskOpenStatus(task: any) {
      const payload: any = {
        ...task,
        commercial: task.commercial.id,
        is_open: !task.is_open
      };

      if (payload.client) {
        payload.client = payload.client.id;
      }
      if (payload.company) {
        payload.company = payload.company.id;
      }
      await this.$store.dispatch("tasks/editTask", payload);
      this.filter();
    }
  }
});
