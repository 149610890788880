<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "@/sass/main.sass";
</style>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    if (this.$store.getters["auth/isLogged"]) {
      this.verifyAccess()
        .then((response) => {
          if (response != 200) {
            this.refreshToken().then((response) => {
              if (response != 200) {
                window.location.href = "/";
              }
            });
          }
        })
        .catch(() => {
          this.logout();
          window.location.href = "/";
        });
    }
  },
  methods: {
    ...mapActions("auth", ["verifyAccess", "refreshToken", "logout"]),
  },
};
</script>
