
import Vue from "vue";

import HistoryTable from "@/components/HistoryTable.vue";
import OrderPaymentNew from "@/components/OrderPaymentNew.vue";
import PrintRow from "@/components/PrintRow.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AccountingsTable",
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("accounting", [
      "ordersList",
      "getStats",
      "client",
      "year",
      "season"
    ]),
    ...mapGetters("orders", ["paymentMethods", "paymentsVersionsList"])
  },
  components: {
    "history-table": HistoryTable,
    "order-payment-new": OrderPaymentNew,
    "print-row": PrintRow
  },
  data() {
    return {
      paymentDialogId: 0,
      paymentMethodsMap: {},
      orderPaymentsHistoryId: 0,
      rowToPdf: 0,
      rowToPdfClientName: "",
      sendPdfEmail: false,
      fields: [
        { label: "brand", text: "Brand" },
        { label: "total_sum", text: "Ordinato" },
        { label: "gross_import", text: "Ordinato (con IVA)" },
        { label: "delivered_import", text: "Consegnato (con IVA)"},
        { label: "payment_method", text: "Mod. pagamento" },
        { label: "due", text: "Scadenza" },
        { label: "import_value", text: "Importo pagamewnto" },
        { label: "cashed", text: "Incassato" },
        { label: "residue", text: "Residuo" }
      ]
    };
  },
  created() {
    this.getMethodsMap(this.paymentMethods);
  },
  watch: {
    paymentMethods(value) {
      this.getMethodsMap(value);
    }
  },
  methods: {
    orderView(id: number){
      window.location.href = "/ordini/" + id;
    },
    getMethodsMap(methods: any) {
      for (const method of methods) {
        this.$data.paymentMethodsMap[method.id] = method.name;
      }
    },
    onlyActive(l: any) {
      return l.filter((x: any) => x.is_active);
    },
    update() {
      this.$store.dispatch("accounting/searchAccountings", {
        client: this.client,
        year: this.year,
        season: this.season
      });
    },
    closePaymentDialog() {
      this.paymentDialogId = 0;
      this.update();
    },
    editCashed(payment: any) {
      this.$store.dispatch("orders/editPayment", payment).then(() => {
        this.update();
      });
    },
    deletePayment(payment: any) {
      payment.is_active = false;
      this.$store.dispatch("orders/editPayment", payment).then(() => {
        this.update();
      });
    },
    showHistory(order: number) {
      if (order > 0) this.$store.dispatch("orders/getPaymentsVersions", order);
      this.orderPaymentsHistoryId = order;
    },
    printRowPdf(id: number, client_name: string, email: boolean) {
      this.rowToPdf = id;
      this.rowToPdfClientName = client_name;
      this.sendPdfEmail = email;
    }
  }
});
