import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface CommercialConditionState {
  conditions: any;
}

type CommercialConditionContext = ActionContext<
  CommercialConditionState,
  RootState
>;

const commercialconditions = {
  namespaced: true,
  state: {
    conditions: []
  },
  getters: {
    commercialConditionsList: (state: CommercialConditionState): any => {
      return state.conditions.results;
    }
  },
  mutations: {
    saveCommercialConditionsList: (
      state: CommercialConditionState,
      list: any
    ): void => {
      state.conditions = list;
    },
    extendsCommercialConditionsList: (
      state: CommercialConditionState,
      obj: any
    ): void => {
      state.conditions.results = state.conditions.results.concat(obj);
    }
  },
  actions: {
    // Get commercialConditions list
    async getCommercialConditionsList(context: CommercialConditionContext) {
      const api = context.rootState.api;

      await fetch(`${api}/commercial-conditions/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveCommercialConditionsList", await response.json());
      });
    },
    // Add new commercial condition
    async newCommercialCondition(
      context: CommercialConditionContext,
      data: any
    ) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/commercial-conditions/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
          context.commit(
            "extendsCommercialConditionsList",
            await response.json()
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Search a commercial condition into the array and delete it
    async deleteCommercialCondition(
      context: CommercialConditionContext,
      index: number
    ) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      const id = context.state.conditions.results[index].id;

      await fetch(`${api}/commercial-conditions/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Condizione commerciale rimossa",
              type: "success"
            },
            { root: true }
          );
          context.state.conditions.results.splice(index, 1);
        } else {
          context.dispatch(
            "toast",
            {
              message: "Condizione commerciale non rimosso",
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    }
  }
};

export default commercialconditions;
