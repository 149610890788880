import agencies from "./targets/agencies";
import { AgencyState } from "./targets/agencies";

import bonuses from "./targets/bonuses";
import { BonusState } from "./targets/bonuses";

import budgets from "./targets/budgets";
import { BudgetState } from "./targets/budgets";

import orders from "./targets/orders";
import { ExpectedOrderState } from "./targets/orders";

export interface TargetState {
  agencies: AgencyState;
  bonuses: BonusState;
  budgets: BudgetState;
  orders: ExpectedOrderState;
}

const targets = {
  namespaced: true,
  modules: {
    agencies,
    bonuses,
    budgets,
    orders,
  },
};

export default targets;
