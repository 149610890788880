import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface BrandState {
  brands: any;
}

type BrandContext = ActionContext<BrandState, RootState>;

const brands = {
  namespaced: true,
  state: {
    brands: []
  },
  getters: {
    brandsList: (state: BrandState): any => {
      return state.brands.results;
    }
  },
  mutations: {
    saveBrandsList: (state: BrandState, list: any): void => {
      state.brands = list;
    },
    extendsBrandsList: (state: BrandState, obj: any): void => {
      state.brands.results = state.brands.results.concat(obj);
    }
  },
  actions: {
    // Get brands list
    async getBrandsList(context: BrandContext) {
      const api = context.rootState.api;

      await fetch(`${api}/brands/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveBrandsList", await response.json());
      });
    },
    // Add new brand
    async newBrand(context: BrandContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/brands/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
          context.commit("extendsBrandsList", await response.json());
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Search a brand into the array and delete it
    async deleteBrand(context: BrandContext, index: number) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      const id = context.state.brands.results[index].id;

      await fetch(`${api}/brands/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Brand rimosso",
              type: "success"
            },
            { root: true }
          );
          context.state.brands.results.splice(index, 1);
        } else {
          const data = await response.json();
          context.dispatch(
            "toast",
            {
              message: data["detail"] ?? "Brand non rimosso",
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Edit a brand
    async editBrand(context: BrandContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/brands/${data.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifiche non salvate",
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    }
  }
};

export default brands;
