import Vue from "vue";
import Vuex from "vuex";
import { Message } from "element-ui";

import { RootState } from "./state";

import auth from "./modules/auth";
import clients from "./modules/clients";
import commercialconditions from "./modules/commercial-conditions";
import companies from "./modules/companies";
import orders from "./modules/orders";
import deviations from "./modules/deviations";
import users from "./modules/users";
import brands from "./modules/brands";
import brandtrends from "./modules/brand-trends";
import profiles from "./modules/profiles";
import targets from "./modules/targets";
import revisions from "./modules/revisions";
import accounting from "./modules/accounting";
import planning from "./modules/planning";
import tasks from "./modules/tasks";
import news from "./modules/news";
import tradefairs from "./modules/tradefairs";
import stats from "./modules/stats";

Vue.use(Vuex);

interface ToastInterface {
  // eslint-disable-next-line
  message: any;
  // eslint-disable-next-line
  type: any;
}

type ToastType = ToastInterface;

const store = new Vuex.Store({
  state: {
    api: process.env.VUE_APP_BACKEND_URL,
    dialogOpen: false,
    loading: false,
  },
  getters: {
    inLoading: (state: RootState): boolean => {
      return state.loading;
    },
    dialogIsOpen: (state: RootState): boolean => {
      return state.dialogOpen;
    },
  },
  mutations: {
    async changeLoadingStatus(state: RootState, value: boolean) {
      state.loading = value;
    },
    changeDialogStatus(state: RootState) {
      state.dialogOpen = !state.dialogOpen;
    },
  },
  actions: {
    // @typescript-eslint/no-unused-vars
    // eslint-disable-next-line
    async toast({}, data: ToastType) {
      if (typeof data.message == "string") {
        Message({ ...data, showClose: true });
      } else if (typeof data.message == "object") {
        for (const key of Object.keys(data.message)) {
          for (const message of data.message[key]) {
            setTimeout(() => {
              Message({
                type: data.type,
                message: `${key}: ${message}`,
                showClose: true,
              });
            }, 200);
          }
        }
      }
    },
    dialog({ commit }) {
      commit("changeDialogStatus");
    },
  },
  modules: {
    auth,
    clients,
    companies,
    commercialconditions,
    orders,
    deviations,
    users,
    brands,
    brandtrends,
    profiles,
    targets,
    revisions,
    accounting,
    planning,
    tasks,
    news,
    tradefairs,
    stats,
  },
});

export default store;
