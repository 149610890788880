import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

import { Loading } from "element-ui";

export interface PlanningState {
  plans: any;
  filters: any;
}

type PlanningContext = ActionContext<PlanningState, RootState>;

const plans = {
  namespaced: true,
  state: {
    plans: [],
    filters: {}
  },
  getters: {
    plansList: (state: PlanningState): any => {
      return state.plans;
    },
    filters: (state: PlanningState): any => {
      return state.filters;
    }
  },
  mutations: {
    savePlans: (state: PlanningState, data: any): void => {
      state.plans = data;
    },
    saveFilters: (state: PlanningState, data: any): void => {
      state.filters = data;
    }
  },
  actions: {
    // Reset plans data
    clearPlannings(context: PlanningContext) {
      context.commit("savePlans ", []);
    },
    // Search orders by year, client and season
    async searchPlannings(context: PlanningContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let result = null;
      let payload: any = {};

      if (!data) {
        payload = context.getters.filters;
      } else {
        for (const key of Object.keys(data)) {
          if (data[key]) {
            if (typeof data[key] == "object" && data[key].length == 0) continue;
            payload[key] = data[key];
          }
        }

        context.commit("saveFilters", payload);
      }

      await fetch(`${api}/plans/filter/?limit=1000`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...payload
        })
      })
        .then(async response => {
          result = await response.json();
          if (response.status != 200) {
            context.dispatch("clearPlannings");
          } else {
            context.commit("savePlans", result.results);
          }
        })
        .catch(() => {
          context.dispatch("clearPlannings");
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Add new plan
    async newPlanning(context: PlanningContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/plans/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Edit a plan
    async editPlanning(context: PlanningContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/plans/${data.plan}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Delete a plan
    async deletePlanning(context: PlanningContext, id: number) {
      const api = context.rootState.api;
      const loading = Loading.service({ fullscreen: true });

      await fetch(`${api}/plans/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        }
      }).then(async response => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Plan eliminato",
              type: "success"
            },
            { root: true }
          );
          context.dispatch("searchPlannings", null);
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });
      loading.close();
    },
    // Add new appointment
    async newAppointment(context: PlanningContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/plans/${data.plan}/appointments/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Edit an appointment
    async editAppoinment(context: PlanningContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/plans/${data.plan}/appointments/${data.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Get appointments calendar
    async appointments(
      context: PlanningContext,
      range: [string, string]
    ): Promise<any> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let data: any;

      await fetch(`${api}/plans/appointments/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...range })
      }).then(async response => {
        data = await response.json();
        if (response.status != 200) {
          context.dispatch(
            "toast",
            {
              message: data,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return data;
    }
  }
};

export default plans;
