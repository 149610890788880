
import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";
import PrintRow from "@/components/PrintRow.vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Customers",
  components: {
    "b-header": Header,
    "blue-top": BlueTop,
    "print-row": PrintRow
  },
  data() {
    return {
      printRowStatus: 0,
      fields: [
        { label: "anagrafica cliente", text: "Anagrafica cliente" },
        { label: "dati", text: "Dati del cliente" },
        { label: "categorie", text: "Categorie" },
        { label: "brands", text: "Brand" },
        { label: "negozio", text: "Negozio" },
        { label: "indirizzi", text: "Indirizzi" },
        { label: "rating", text: "Rating commerciale e finanziario" },
        { label: "social", text: "Social" },
        { label: "responsabile", text: "Responsabile" },
        { label: "note", text: "Note" }
      ]
    };
  },
  created() {
    const query = this.$route.query;

    if (query["q"] && query.q.length >= 3) {
      this.$store.dispatch("clients/searchClients", query.q);
    } else {
      this.$store.dispatch("clients/getClientsList");
    }
  },
  computed: {
    ...mapGetters({ newOrderDialog: "dialogIsOpen" }),
    ...mapGetters("clients", { tableData: "clientsList" })
  },
  methods: {
    printRow(id: number) {
      this.printRowStatus = id;
    },
    editRow(id: number) {
      window.location.href = "/clienti/" + id;
    },
  }
});
