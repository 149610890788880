
import Vue from "vue";

import newUsers from "@/views/settings/newUsers.vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "SettingsUsers",
  components: {
    "new-users": newUsers
  },
  data() {
    return {
      addUser: false,
      userData: {}
    };
  },
  created() {
    this.findMe().then(() => {
      this.getUsersList();
    });
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("users", ["usersList"])
  },
  methods: {
    editMfaMethod(userId: number, method: any) {
      this.editMfa({ id: userId, is_active: method.is_active });
    },
    newUser() {
      this.userData = {};
      this.addUser = true;
    },
    editUser(data: any) {
      this.userData = { ...data };
      this.addUser = true;
    },
    ...mapActions("auth", ["findMe"]),
    ...mapActions("users", ["getUsersList", "editMfa"])
  }
});
