<template lang="pug">
  form 
    section.container
      aside
        #filter
          .containerFilter 
            .reset
              el-button(v-if="reset" @click="filterOrders(true)") Reset
            ul
              li
                h4 Cliente
                div.clienti-select
                  el-select(
                    filterable clearable multiple
                    v-model="filtersOrdersList.clients"
                    @change="filterOrders()"
                  )
                    el-option(
                      :label="client.corporate_name" :value="client.id"
                      v-for="client in clientsList"
                      :key="client.id"
                    ) {{ client.corporate_name }}
              li                 
                h4 Agente
                div.agente-select
                  el-select(
                    filterable clearable multiple
                    v-model="filtersOrdersList.commercials"
                    @change="filterOrders()"
                  )
                    el-option(
                      :label="commercial.email" :value="commercial.id"
                      v-for="commercial in commercialsList"
                      :key="commercial.id"
                    ) {{ commercial.email }}
              li
                h4 Stato Completato
                el-select(
                  filterable clearable
                  v-model="filtersOrdersList.is_completed"
                  @change="filterOrders()"
                )
                  el-option(
                    :label="'Sì'" :value="true"
                  ) Sì
                  el-option(
                    :label="'No'" :value="false"
                  ) No
              li
                h4 Data Ordine
                el-date-picker(
                  v-model="filtersOrdersList.orderDate"
                  type="daterange"
                  range-separator="A"
                  start-placeholder="Data inizio"
                  end-placeholder="Data fine"
                  @change="filterOrders()"
                )
</template>


<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FilterOrders",
  data() {
    return {
      filtersOrdersList: {
        clients: [],
        commercials: [],
        is_completed: null,
        orderDate: [],
      },
      reset: false,
      clientsList: [],
      commercialsList: [],
    };
  },
  computed: {
    ...mapGetters("orders", ["mapData"]),
  },
  mounted() {
    this.$store.dispatch("orders/getMap", {}).then(() => {
      const maps = this.mapData;
      console.log('maps di filterorders mounted', maps);

      // Popola clientsList con oggetti unici
      const clientsMap = new Map();
      maps.forEach(x => {
        if (x.client && x.client.id && !clientsMap.has(x.client.id)) {
          clientsMap.set(x.client.id, x.client);
        }
      });
      this.clientsList = Array.from(clientsMap.values());
      console.log('clientsList:', this.clientsList);

      // Popola commercialsList con oggetti unici
      const commercialsMap = new Map();
      maps.forEach(x => {
        if (x.commercial && x.commercial.id && !commercialsMap.has(x.commercial.id)) {
          commercialsMap.set(x.commercial.id, x.commercial);
        }
      });
      this.commercialsList = Array.from(commercialsMap.values());
      console.log('commercialsList:', this.commercialsList);
    });
  },
  watch: {
    filtersOrdersList: {
      handler(value) {
        this.reset = Object.values(this.filtersOrdersList).some(
          val => (Array.isArray(val) && val.length) || (!Array.isArray(val) && val !== null)
        );
      },
      deep: true,
    },
  },
  methods: {
    async filterOrders(reset) {
      if (reset) {
        this.$data.reset = false;
        for (const key of Object.keys(this.$data.filtersOrdersList)) {
          if (Array.isArray(this.$data.filtersOrdersList[key])) {
            this.$data.filtersOrdersList[key] = [];
          } else {
            this.$data.filtersOrdersList[key] = null;
          }
        }
        this.$store.dispatch("orders/getOrdersList");
      } else {
        let f = { ...this.$data.filtersOrdersList };

          if (f.commercials) {
          if (!Array.isArray(f.commercials)) { 
            f.commercials = [f.commercials];
          }
        }

        // Se 'commercials' contiene oggetti, mappali agli ID
        if (f.commercials && Array.isArray(f.commercials)) {
          if (typeof f.commercials[0] !== 'number') {
            f.commercials = f.commercials.map(commercial => commercial.id);
          }
        }

        // Rimuovi chiavi con valori nulli, undefined, stringhe vuote o array vuoti
        for (const key of Object.keys(f)) {
          const val = f[key];
          if (
            val === null ||
            val === undefined ||
            (typeof val === 'string' && val.trim() === '') ||
            (Array.isArray(val) && val.length === 0)
          ) {
            delete f[key];
          }
        }

        // Gestione di orderDate per estrarre date_from e date_to
        if (f.orderDate && f.orderDate.length === 2) {
          const dateFrom = new Date(f.orderDate[0]);
          const dateTo = new Date(f.orderDate[1]);

          if (!isNaN(dateFrom) && !isNaN(dateTo)) {
            f.date_from = dateFrom.toISOString().split('T')[0];
            f.date_to = dateTo.toISOString().split('T')[0];
          }
        }
        delete f.orderDate;

        console.log('f.commercials:', f.commercials);
        console.log('Filtri inviati al server:', f);
        this.$store.dispatch('orders/filterOrders', f);
      }
    },
  }
});
</script>




<!-- <script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FilterOrders",
  data() {
    return {
      // Oggetto che contiene i filtri applicabili agli ordini
      filtersOrdersList: {},
      reset: false, // Booleano per il pulsante reset
      clientsList: [],
      commercialsList: [],
      is_completed: [],
      orderDatedates: [],
    };
  },
  computed: {
    ...mapGetters("orders", ["mapData"]),

      // Computed property per clientsList
    // clientsList() {
    //   const maps = this.mapData || [];
    //   const clientsSet = new Set(maps.map(x => x.client));
    //   return Array.from(clientsSet);
    // },
   
    // // Computed property per commercialsList
    // commercialsList() {
    //   const maps = this.mapData || [];
    //   const commercialsSet = new Set(maps.map(x => x.commercial));
    //   return Array.from(commercialsSet);
    // },
   
    // // Computed property per dates
    // dates() {
    //   const maps = this.mapData || [];
    //   return maps.map(x => new Date(x.date));
    // },
   
    // // Computed property per completed (is_completed)
    // is_completed() {
    //   const maps = this.mapData || [];
    //   const completedSet = new Set(maps.map(x => (x.is_completed ? 'Completato' : 'Non completato')));
    //   return Array.from(completedSet);
    // },
  },
  mounted() {
    // this.$store.dispatch("orders/getMap", {});
    // Ottieni i dati necessari dal Vuex store all'avvio del componente
    // this.$store.dispatch("clients/getClientsList");
    this.$store.dispatch("orders/getMap", {}).then(() => {
      const maps = this.mapData;
      console.log('maps di filterorders mounted',maps);
      this.clientsList = Array.from(new Set(maps.map(x => x.client)));
      this.commercialsList = Array.from(new Set(maps.map(x => x.commercial)));
      // this.is_completed = Array.from(new Set(maps.map(x => x.is_completed ? 'Completato' : 'Non completato')));
      // this.dates = maps.map(x => new Date(x.date));
    })
  },
  watch: {
   filtersOrdersList: {
     handler(value) {
      this.reset = Object.values(this.filtersOrdersList).some(
        val => (Array.isArray(val) && val.length) || (!Array.isArray(val) && val !== null)
      );
     },
     deep: true,
   },
  },
  methods: {
    // Metodo per filtrare gli ordini
    async filterOrders(reset) {
      if (reset) {
        this.$data.reset = false;
        // Resetta i filtri impostando array vuoti o null
        for (const key of Object.keys(this.$data.filtersOrdersList)) {
          if (Array.isArray(this.$data.filtersOrdersList[key])) {
            this.$data.filtersOrdersList[key] = [];
          } else {
            this.$data.filtersOrdersList[key] = null;
          }
        }
        // Richiama l'azione per ottenere la lista completa degli ordini
        this.$store.dispatch("orders/getOrdersList");
      } else {
          let f = { ...this.$data.filtersOrdersList };

          if (f.clients && Array.isArray(f.clients)) {
           f.clients = f.clients.map(client => client); // Assicurati di inviare solo il nome
          }  

          if (f.commercials && !Array.isArray(f.commercials)) {
              f.commercials = f.commercials.map(commercial => commercial.id);
          }

          // Gestione di orderDate per estrarre date_from e date_to
          if (f.orderDate && f.orderDate.length === 2) {
            const dateFrom = new Date(f.orderDate[0]);
            const dateTo = new Date(f.orderDate[1]);
        
          if (!isNaN(dateFrom) && !isNaN(dateTo)) {
            f.date_from = dateFrom.toISOString().split('T')[0];
            f.date_to = dateTo.toISOString().split('T')[0];
          }
        }
        delete f.orderDate; // Rimuovi orderDate dopo aver estratto le date
      
        // Rimuovi chiavi con valori nulli, undefined, stringhe vuote o array vuoti
        for (const key of Object.keys(f)) {
          const val = f[key];
          if (
            val === null ||
            val === undefined ||
            (typeof val === 'string' && val.trim() === '') ||
            (Array.isArray(val) && val.length === 0)
          ) {
            delete f[key];
          }
        }
      
        // Esegui l'azione per filtrare gli ordini con i filtri aggiornati
        this.$store.dispatch("orders/filterOrders", f);

        console.log('Filtri inviati al server:', f);   // Logga i filtri prima di inviarli   
        this.$store.dispatch('orders/filterOrders', f); // Chiama l'azione Vuex per filtrare gli ordini
      }
    },              
  }  
});
</script> -->