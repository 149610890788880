import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

import { Loading } from "element-ui";

export interface TradeFairState {
  tradefairs: any;
  filters: any;
}

type TradeFairContext = ActionContext<TradeFairState, RootState>;

const tradefairs = {
  namespaced: true,
  state: {
    tradefairs: [],
    filters: {}
  },
  getters: {
    tradefairsList: (state: TradeFairState): any => {
      return state.tradefairs;
    },
    filters: (state: TradeFairState): any => {
      return state.filters;
    }
  },
  mutations: {
    saveTradeFairs: (state: TradeFairState, data: any): void => {
      state.tradefairs = data.results;
    },
    saveFilters: (state: TradeFairState, data: any): void => {
      state.filters = data;
    }
  },
  actions: {
    async getTradefairsList(
      context: TradeFairContext,
      limit: number | undefined
    ) {
      const api = context.rootState.api;

      let path = `${api}/trade-fairs/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveTradeFairs", await response.json());
      });
    },
    // Find a trade fair
    async findTradefair(context: TradeFairContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/trade-fairs/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Add new tradefair
    async newTradeFair(context: TradeFairContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/trade-fairs/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Edit a tradefair
    async editTradeFair(context: TradeFairContext, data: any): Promise<number> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/trade-fairs/${data.id}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Delete a tradefair
    async deleteTradeFair(context: TradeFairContext, id: number) {
      const api = context.rootState.api;
      const loading = Loading.service({ fullscreen: true });

      await fetch(`${api}/trade-fairs/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        }
      }).then(async response => {
        const status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Plan eliminato",
              type: "success"
            },
            { root: true }
          );
          context.dispatch("searchTradeFairs", null);
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        }
      });
      loading.close();
    }
  }
};

export default tradefairs;
