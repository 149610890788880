
import Vue from "vue";

import Header from "@/components/Header.vue";
import BlueTop from "@/components/BlueTop.vue";
import PrintRow from "@/components/PrintRow.vue";
import HistoryTable from "@/components/HistoryTable.vue";
import { mapGetters } from "vuex";
import { OrdersData } from "@/interfaces/OrdersData.interface";
import { OrdersComponent } from "@/interfaces/ordersComponent.interface";


export default Vue.extend({
  name: "Orders",
  components: {
    "b-header": Header,
    "blue-top": BlueTop,
    "print-row": PrintRow,
    "history-table": HistoryTable
  },
  data() {
    return {
      printRowStatus: 0,
      orderHistoryId: 0,
      fields: [
        { label: "cod", text: "Codice" },
        { label: "client", text: "Dati del cliente" },
        { label: "date", text: "Data" },
        { label: "company_line", text: "Mandante" },
        { label: "actual_delivery", text: "Consegnato effettivo" },
        { label: "commercial", text: "Agente" },
        { label: "season", text: "Stagione" },
        { label: "year", text: "Anno" },
        { label: "payment", text: "Pagamento" },
        { label: "num_of_pieces", text: "Num pezzi" },
        { label: "commissions", text: "Dettaglio provvigioni" },
        { label: "total_sum", text: "Importo" },
        { label: "conditions", text: "Condizioni" },
        { label: "note", text: "Note" },
      ],
    };
  },
  created (this: OrdersComponent) {
    const query = this.$route.query;

    if (query["q"] && (query.q as string).length >= 3) {
        // Se c'è una query di ricerca per ordini, effettua la ricerca
        this.$store.dispatch("orders/searchOrders", query.q);
    } else {
        // Altrimenti, ottieni la lista completa degli ordini e ottieni la lista dei clienti
        this.$store.dispatch("orders/getOrdersList").then(() => {
          console.log(this.tableData);
    });
    }
  },
  computed: {
    ...mapGetters({ newOrderDialog: "dialogIsOpen" }),
    ...mapGetters("orders", { tableData: "ordersList" }),
    ...mapGetters("orders", ["versionsList"]),
    // filteredOrders(this: OrdersComponent) {
    //   let orders = this.tableData;

    //   // Filtro per lo stato di completamento
    //   if (this.filtersOrdersList.is_completed !== null) {
    //     orders = orders.filter(order => order.is_completed === this.filtersOrdersList.is_completed);
    //   }

    //   if (this.filtersOrdersList.commercials !== null) {
    //     orders = orders.filter(order => order.commercial && order.commercial.id === this.filtersOrdersList.commercials);
    //   }

    //   // Filtro per il range di date
    //   const [startDate, endDate] = this.filtersOrdersList.orderDate || [null, null];
    // if (startDate && endDate) {
    //   orders = orders.filter(order => {
    //     // Controlla se order.date esiste e non è null
    //     if (!order.date) return false; // Escludi gli ordini senza data

    //     const orderDate = new Date(order.date);
    //     return orderDate >= startDate && orderDate <= endDate;
    //   });
    // }

    // return orders;
    // },
  },
  methods: {
    // `0` in `showedId` is used to reset the COD to expand
    toggleCod(this: OrdersComponent, id: number) {
      this.$data.showedId = this.showedId == id ? 0 : id;
    },
    printRow(this: OrdersComponent, id: number) {
      this.$data.printRowStatus = id;
    },

    editRow(this: OrdersComponent, id: number) {
      window.location.href = "/ordini/" + id;
    },
    showHistory(this:OrdersComponent, order: number) {
      if (order > 0) this.$store.dispatch("orders/getVersions", order);
      this.orderHistoryId = order;
    },
    
  },
  
});
