import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface BrandTrendState {
  brands: any;
}

type BrandTrendContext = ActionContext<BrandTrendState, RootState>;

const brands = {
  namespaced: true,
  state: {
    brands: [],
  },
  getters: {
    brandTrendsList: (state: BrandTrendState): any => {
      return state.brands.results;
    },
  },
  mutations: {
    saveBrandTrendsList: (state: BrandTrendState, list: any): void => {
      state.brands = list;
    },
    extendsBrandTrendsList: (state: BrandTrendState, obj: any): void => {
      state.brands.results = state.brands.results.concat(obj);
    },
  },
  actions: {
    // Get brands list
    async getBrandTrendsList(context: BrandTrendContext) {
      const api = context.rootState.api;

      await fetch(`${api}/orders/brand-trends/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        context.commit("saveBrandTrendsList", await response.json());
      });
    },
    // Add new brand
    async newBrandTrend(context: BrandTrendContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/brand-trends/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
          context.commit("extendsBrandTrendsList", await response.json());
        } else {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Edit a brand
    async editBrandTrend(context: BrandTrendContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/orders/brand-trends/${data.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        const status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success",
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifiche non salvate",
              type: "error",
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
  },
};

export default brands;
