import { User } from "@sentry/vue";
import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface UserState {
  users: any;
}

type UserContext = ActionContext<UserState, RootState>;

const users = {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    usersList: (state: UserState): any => {
      return state.users.results;
    }
  },
  mutations: {
    saveUsersList: (state: UserState, list: any): void => {
      state.users = list;
    }
  },
  actions: {
    // Get users list
    async getUsersList(context: UserContext) {
      const api = context.rootState.api;

      await fetch(`${api}/profiles/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveUsersList", await response.json());
      });
    },
    // Edit a MFA method
    async editMfa(context: UserContext, payload: any): Promise<any> {
      const api = context.rootState.api;

      await fetch(`${api}/auth/mfa/user-edit-method/${payload.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-type": "application/json"
        },
        body: JSON.stringify({ is_active: payload.is_active })
      }).then(async response => {
        const result = {
          data: await response.json(),
          status: response.status
        };

        if (result.status != 200) {
          context.dispatch(
            "toast",
            {
              message: result.data,
              type: "error"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "MFA salvato",
              type: "success"
            },
            { root: true }
          );
        }
      });
    },
    // Save User
    async newUser(context: UserContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/users/signup/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          if (status == 400 && res.email) {
            context.commit("saveUserList", res.email)
          }
           context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true}
           );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
  }
};

export default users;
