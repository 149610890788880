import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface NewsState {
  news: any;
}

type NewsContext = ActionContext<NewsState, RootState>;

const news = {
  namespaced: true,
  state: {
    news: {}
  },
  getters: {
    newsList: (state: NewsState): any => {
      return state.news.results;
    }
  },
  mutations: {
    saveNewsList: (state: NewsState, list: any): void => {
      state.news = list;
    }
  },
  actions: {
    // Search into the API the list of news. Use `Authorization` to see that
    // list.
    async getNewsList(context: NewsContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/news/`;
      if (limit) {
        path += "?limit=" + limit;
      }
      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveNewsList", await response.json());
      });
    },
    // Find a news by its `id`
    async findNews(context: NewsContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/news/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Create a new news
    async newNews(context: NewsContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/news/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "News creata",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    }
  }
};

export default news;
