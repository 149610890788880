import store from "@/store";

// eslint-disable-next-line
const loginBeforeEnter = function (to: any, from: any, next: any) {
  if (store.getters["auth/isLogged"]) {
    next();
  } else {
    next({ name: "Home", query: { ref: to["fullPath"] } });
  }
};

export default loginBeforeEnter;
