<template lang="pug">
  .map-orders(v-if="client")
    aside
      el-card.head(:class="client.status.toLowerCase()")
        h3 {{ client.corporate_name }}
        span {{ client.legal_address }}

      .head
        .filters
          label Filtra
          el-select(
            v-model="filters.season_year"
            filterable
            placeholder="stagione"
            clearable
            @change="handleFilter"
          )
            el-option(
              v-for="season in seasons"
              :key="season.season+'_'+season.year"
              :label="season.season+' '+season.year"
              :value="season.season+'_'+season.year"
            )
          el-select(
            v-model="filters.brand"
            multiple
            filterable
            collapse-tags
            placeholder="brand"
            allow-create
            clearable
            @change="handleFilter"
          )
            el-option(
              v-for="brand in brandsList"
              :key="brand.id"
              :label="brand.name"
              :value="brand.id"
            )

      hr

      el-table(
        :data="clientOrders"
      )
        el-table-column(prop="brand.name" label="Brand")
        el-table-column(prop="brand_trend.name" label="Uscita")
        el-table-column(prop="number_of_pieces" label="N. pez")
        el-table-column(prop="total_sum" label="Importo")

</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ClientOrder",
  props: ["client"],
  data() {
    return {
      filters: {
        season_year: null,
        brand: [],
      },
      clientOrders: [],
    };
  },
  computed: {
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("orders", ["seasons"]),
  },
  mounted() {
    this.orders();
  },
  watch: {
    client: function () {
      this.orders();
    },
  },
  methods: {
    async orders() {
      if (this.client) {
        await this.$store
          .dispatch("orders/searchOrders", this.client.id)
          .then(async (response) => {
            this.clientOrders = response;
          });
      }
    },
    async handleFilter() {
      await this.orders();

      let rows = this.clientOrders;
      if (this.filters.season_year) {
        const season_year_split = this.filters.season_year.split("_");
        rows = rows.filter(
          (x) =>
            x.date.substr(0, 4) == season_year_split[1] &&
            x.season == season_year_split[0]
        );
      }

      if (this.filters.brand.length > 0) {
        rows = rows.filter((x) => this.filters.brand.indexOf(x.brand.id) > -1);
      }

      this.$set(this.$data, "clientOrders", rows);
    },
  },
});
</script>
