
import Vue from "vue";

import Header from "@/components/Header.vue";
import PrintRow from "@/components/PrintRow.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "CompaniesEdit",
  props: ["id"],
  components: {
    "b-header": Header,
    "print-row": PrintRow,
    "history-table": HistoryTable
  },
  data() {
    var validatePhone = (rule: any, value: any, callback: any) => {
      for (const ch of value) {
        if (ch == "+" || ch == " " || ch == "0") continue;

        if (!Number(ch)) {
          callback(new Error("Non è un numero valido"));
          return;
        }
      }

      callback();
    };

    return {
      printCustomerStatus: false,
      map_key: process.env.VUE_APP_MAPS_KEY,
      addressesAreEquals: false,
      statusDropdown: false,
      tag: "",
      inputVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      data: {
        id: 0,
        status: "",
        manager: [
          {
            name: "",
            phone: "",
            cellular: "",
            email: "",
            label: ""
          }
        ],
        lines: [{ brand: null, brand_trend: null }],
        courier: [],
        website: "",
        city: "",
        province: "",
        postcode: "",
        region: "",
        other_addresses: []
      },
      cacheForm: {},
      lineForm: {
        brand: null,
        brand_trend: null,
        commercial: null,
        commission_commercial: null
      },
      fields: [
        { label: "anagrafica", text: "Anagrafica azienda" },
        { label: "dati", text: "Dati del cliente" },
        { label: "linee", text: "Linee" },
      ],
      couriers: ["BRT", "PosteItaliane", "DHL", "GLS", "FedEx", "UPS"],
      other_addresses_options: [
        {
          value: "LE",
          label: "Sede legale"
        },
        {
          value: "ME",
          label: "Logistica"
        },
        {
          value: "ST",
          label: "Punto vendita"
        }
      ],
      isNew: false,
      rules: {
        corporate_name: [
          {
            required: true,
            message: "Inserisci un nome cliente",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
          {
            type: "email",
            message: "Email non valida",
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        status: [
          { required: true, message: "Seleziona uno stato", trigger: "blur" }
        ]
      },
      myTasks: []
    };
  },
  computed: {
    hasChanged(): boolean {
      const obj1 = JSON.stringify(this.cacheForm);
      const obj2 = JSON.stringify(this.data);
      return obj1 !== obj2;
    },
    ...mapGetters("auth", ["me"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("brandtrends", ["brandTrendsList"]),
    ...mapGetters("companies", ["versionsList"])
  },
  async created() {
    this.$store.dispatch("users/getUsersList");
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("brandtrends/getBrandTrendsList");

    if (this.$props.id != "nuovo") {
      await this.findCompany(this.id)
        .then(response => {
          if (response.status == 200) {
            this.$set(this.$data, "data", response.data);
            for (let line of this.data.lines) {
              const brand: any = line?.brand;
              line.brand = brand.id;
              const brand_trend: any = line?.brand_trend;
              line.brand_trend = brand_trend.id;
            }
            if (
              Object.prototype.toString.call(this.$data.data.manager) !==
              "[object Array]"
            ) {
              this.$data.data.manager = [this.$data.data.manager];
            }
            this.$data.addressesAreEquals =
              response.data.legal_address ==
              response.data.merchandise_delivery_address;
            this.getVersions(this.data.id);
            this.cacheForm = JSON.parse(JSON.stringify(response.data));
            window.addEventListener("beforeunload", this.handlerClose);
          } else {
            window.location.href = "/aziende";
          }
        })
        .catch(() => {
          window.location.href = "/aziende";
        });
      this.$store
        .dispatch("tasks/search", {
          is_open: true,
          company: this.id
        })
        .then(response => {
          this.$data.myTasks = response;
        });
    } else {
      this.isNew = true;
      this.data.lines = [];
    }
  },
  watch: {
    lineForm: {
      handler: function(value) {
        if (this.getContract(value.commercial) == "FX") {
          value.commission_commercial = 0;
        }
      },
      deep: true
    }
  },
  methods: {
    handlerClose(event: any) {
      if (this.hasChanged) {
        event.preventDefault();
        event.returnValue = ""; // Used for Chrome
      }
    },
    getContract(id: number) {
      // This is used because that value might be be undefined on mounting, so
      // the line above would raises an exception
      if (!this.$store.getters["users/usersList"]) return "NC";

      const users = this.$store.getters["users/usersList"].filter(
        (x: any) => x.id == id
      );
      if (users && users.length) return users[0].contract_type;

      return "NC";
    },
    deleteAddressRow(index: number) {
      this.$data.data.other_addresses.splice(index, 1);
    },
    newOtherAddress() {
      this.$data.data.other_addresses.push({ address_type: null, address: "" });
    },
    sameAddress(event: any) {
      if (event) {
        this.$set(
          this.$data.data,
          "merchandise_delivery_address",
          this.$data.data.legal_address
        );
      }
      this.$data.addressesAreEquals = event;
    },
    setStatus(status: string) {
      this.data.status = status;
      this.$set(this.data, status, status);
      this.statusDropdown = false;
    },
    printCustomer() {
      this.printCustomerStatus = !this.printCustomerStatus;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        // eslint-disable-next-line
        const input: any = this.$refs.saveTagInput;
        input.$refs.input.focus();
      });
    },
    setPlaceForOtherAddress(place: any, index: number) {
      this.$set(
        this.data.other_addresses[index],
        "address",
        place.formatted_address
      );
    },
    // eslint-disable-next-line
    getAddress(place: any, field: string) {
      this.$set(this.data, field, place.formatted_address);
    },
    legalAddressMap(place: any) {
      if (place.address_components.length == 7) {
        let newAddressComponents = new Array(8);
        newAddressComponents[0] = "0";

        for (let i = 1; i < 8; i++) {
          newAddressComponents[i] = place.address_components[i - 1];
        }

        place.address_components = newAddressComponents;
      }
      
      this.getAddress(place, "legal_address");
      this.data.city = place.address_components[3].short_name;
      this.data.province = place.address_components[4].short_name;
      this.data.postcode = place.address_components[7].short_name;
      this.data.region = place.address_components[5].short_name;
    },
    merchandiseDeliveryAddressMap(place: any) {
      this.getAddress(place, "merchandise_delivery_address");
    },
    save() {
      (this.$refs.form as Vue & {
        validate: (cb: (valid: boolean) => void) => void;
      }).validate((valid: boolean) => {
        if (valid) {
          const website = this.data.website;
          if (website != "") {
            if (
              !(website.indexOf("http:") == 0 || website.indexOf("https:") == 0)
            ) {
              this.data.website = "https://" + website;
            }
          }

          if (this.isNew) {
            this.newCompany(this.data).then(async data => {
              if (data["id"]) {
                for (let line of this.data.lines) {
                  let f: any = line;
                  f.company = data.id;
                  await this.$store.dispatch("companies/newLine", f);
                }
                setTimeout(() => {
                  window.location.href = "/aziende/" + data.id;
                }, 100);
              }
            });
          } else {
            this.editCompany(this.data).then(async response => {
              if ((await response) == 200) {
                // Prevent errors on page leaving
                this.cacheForm = this.data;
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }
            });
          }
        } else {
          this.toast({
            message: "Riempi i campi obbligatori",
            type: "error"
          });
        }
      });
    },
    saveNewLine() {
      let f = { ...this.lineForm, company: null };
      if (
        !(
          f.brand &&
          f.brand_trend &&
          f.commercial &&
          f.commission_commercial !== null
        )
      ) {
        this.toast({
          message: "Riempi tutti i campi",
          type: "error"
        });
        return;
      }

      if (this.isNew) {
        this.$data.data.lines.push(f);
        this.$data.lineForm = {
          brand: null,
          brand_trend: null,
          commercial: null,
          commission_commercial: null
        };
      } else {
        f.company = this.id;

        this.$store.dispatch("companies/newLine", f).then((response: any) => {
          if (response["id"]) {
            this.$data.data.lines.push(response);
            this.$data.lineForm = {
              brand: null,
              brand_trend: null,
              commercial: null,
              commission_commercial: null
            };
            this.getVersions(this.data.id);
          }
        });
      }
    },
    removeCompanyLine(id: number, index: number) {
      if (!id) {
        this.$data.data.lines.splice(index, 1);
      } else {
        this.$store
          .dispatch("companies/removeCompanyLine", id)
          .then((status: number) => {
            if (status == 204) {
              this.$data.data.lines.splice(index, 1);
              this.getVersions(this.data.id);
            }
          });
      }
    },
    async closeTask(task: any) {
      const payload: any = {
        ...task,
        commercial: task.commercial.id,
        company: task.company.id,
        is_open: false
      };

      await this.$store.dispatch("tasks/editTask", payload);
    },
    newManagerRow() {
      this.$data.data.manager.push({
        name: "",
        phone: "",
        cellular: "",
        email: "",
        label: ""
      });
    },
    deleteManagerRow(i: number) {
      if (i < 1 || i >= this.$data.data.manager.length) return;

      this.$data.data.manager.splice(i, 1);
    },
    // An helper for the `saveLine` for the companies store. It updates the
    // commission commercial in case of fixed commercials.
    saveLine(line: any) {
      if (this.getContract(line.commercial) == "FX")
        line.commission_commercial = 0;

      this.$store.dispatch("companies/saveLine", line);
    },
    ...mapActions(["toast"]),
    ...mapActions("companies", [
      "findCompany",
      "getVersions",
      "newCompany",
      "editCompany"
    ])
  }
});
