import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface DeviationState {
  deviations: any;
  count: number;
}

type DeviationContext = ActionContext<DeviationState, RootState>;

const deviations = {
  namespaced: true,
  state: {
    deviations: {},
    count: 0
  },
  getters: {
    deviationsList: (state: DeviationState): any => {
      return state.deviations.results;
    },
    deviationsCount: (state: DeviationState): number => {
      return state.deviations.count;
    }
  },
  mutations: {
    saveDeviationsList: (state: DeviationState, list: any): void => {
      state.deviations = list;
    },
    saveDeviationsCount: (state: DeviationState, count: number): void => {
      state.count = count;
    }
  },
  actions: {
    // Search into the API the list of deviations. Use `Authorization` to see that
    // list.
    async getDeviationsList(
      context: DeviationContext,
      limit: number | undefined
    ) {
      const api = context.rootState.api;

      let path = `${api}/orders/deviations/`;
      if (limit) {
        path += "?limit=" + limit;
      }

      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveDeviationsList", await response.json());
      });
    },
    // Find an order by its `id`
    async findDeviation(context: DeviationContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/orders/deviations/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Create a new deviation
    async newDeviation(context: DeviationContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/orders/deviations/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Edit an order. In `data` we have an object got from `findOrder`
    async editDeviation(context: DeviationContext, data: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;
      let res: any;

      await fetch(`${api}/orders/deviations/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    }
  }
};

export default deviations;
