import { ActionContext } from "vuex";
import { RootState } from "@/store/state";
import moment from "moment";

export interface AccountingState {
  orders: any;
  currentClient: number;
  currentSeason: number;
  currentYear: number;
  currentBrand: number;
  filterResidue: boolean;
}

type AccountingContext = ActionContext<AccountingState, RootState>;

const accounting = {
  namespaced: true,
  state: {
    orders: [],
    currentClient: 0,
    currentSeason: 0,
    currentYear: 0,
    currentBrand: 0,
    filterResidue: false
  },
  getters: {
    ordersList: (state: AccountingState): any => {
      if (state.filterResidue) {
        return state.orders.filter((x: any) => x.residue > 0);
      }
      return state.orders;
    },
    client: (state: AccountingState): number => {
      return state.currentClient;
    },
    year: (state: AccountingState): number => {
      return state.currentYear;
    },
    season: (state: AccountingState): number => {
      return state.currentSeason;
    },
    brand: (state: AccountingState): number => {
      return state.currentBrand;
    },
    getStats: (state: AccountingState): any => {
      const stats = { brands: 0, total_sums: 0, gross_imports: 0 };
      let orders = state.orders;

      if (state.filterResidue) {
        orders = orders.filter((x: any) => x.residue > 0);
      }

      for (const i of orders) {
        stats.brands++;
        stats.total_sums += Number(i.total_sum);
        stats.gross_imports += Number(i.gross_import);
      }
      return [stats];
    }
  },
  mutations: {
    saveOrders: (state: AccountingState, data: any): void => {
      state.orders = data;
    },
    saveCurrentData: (state: AccountingState, data: any): void => {
      state.currentClient = data.client;
      state.currentSeason = data.season;
      state.currentYear = data.year;
      state.currentBrand = data.brand;
    },
    saveFilterCheck: (state: AccountingState, checked: boolean): void => {
      state.filterResidue = checked;
    }
  },
  actions: {
    // Reset order data
    clearAccountings(context: AccountingContext) {
      context.commit("saveOrders ", []);
    },
    // When checked uses only orders with payments with residue
    filterByResidueGreaterThan0(context: AccountingContext, checked: boolean) {
      context.commit("saveFilterCheck", checked);
    },
    // Search orders by year, client and season
    async searchAccountings(context: AccountingContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let result = null;

      context.commit("saveCurrentData", { ...data });

      await fetch(`${api}/orders/accounting/?limit=1000`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ...data
        })
      })
        .then(async response => {
          result = await response.json();
          if (response.status != 200) {
            context.dispatch("clearAccountings");
          } else {
            context.commit("saveOrders", result.results);
          }
        })
        .catch(() => {
          context.dispatch("clearAccounting");
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Make the call to print accounting as PDF file. Use `fields` as
    // parameters on GET
    async print(context: AccountingContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/orders/accounting/print/`;
      if (data["id"]) path += data.id + "/";

      path += "?";

      for (const field of data.fields) path += field + "&";

      const pdfError = () => {
        context.dispatch(
          "toast",
          {
            message: "Errore nella generazione del PDF",
            type: "error"
          },
          { root: true }
        );
      };

      fetch(path, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          client: context.getters.client,
          year: context.getters.year,
          season: context.getters.season
        })
      })
        .then(async r => {
          const today = moment().format("DD-MM-YYYY");
          const name = data.client ?? "Amministrazione";
          return {
            filename: `${name} - ${today}.pdf`,
            blob: await r.blob(),
            headers: r.headers,
            status: r.status
          };
        })
        .then(obj => {
          if (obj.status != 200) {
            pdfError();
            return;
          }
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch(() => {
          pdfError();
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    },
    // Send an email to the client with the rendered PDF table
    async email(context: AccountingContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/orders/accounting/email/`;
      if (data["id"]) path += data.id + "/";

      path += "?";

      for (const field of data.fields) path += field + "&";
      fetch(path, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          client: context.getters.client,
          year: context.getters.year,
          season: context.getters.season,
          cc_emails: data.cc_emails
        })
      })
        .then(obj => {
          if (obj.status == 200) {
            context.dispatch(
              "toast",
              {
                message: "Email inviata con successo",
                type: "success"
              },
              { root: true }
            );
          } else {
            context.dispatch(
              "toast",
              {
                message: "Email non inviata",
                type: "error"
              },
              { root: true }
            );
          }
        })
        .catch((e: any) => {
          context.dispatch(
            "toast",
            {
              message: e,
              type: "error"
            },
            { root: true }
          );
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    }
  }
};

export default accounting;
