
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "SettingsPaymentMethods",
  data() {
    return { paymentMethodName: "" };
  },
  mounted() {
    this.getPaymentMethods();
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("orders", ["paymentMethods"]),
  },
  methods: {
    saveNewPaymentMethod() {
      this.newPaymentMethod({ name: this.paymentMethodName }).then(
        () => (this.paymentMethodName = "")
      );
    },
    ...mapActions("orders", [
      "getPaymentMethods",
      "newPaymentMethod",
      "deletePaymentMethod",
      "editPaymentMethod",
    ]),
  },
});
