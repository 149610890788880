import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface CompanyState {
  companies: any;
  versions: any;
  map: any;
}

type CompanyContext = ActionContext<CompanyState, RootState>;

const companies = {
  namespaced: true,
  state: {
    companies: {},
    versions: [],
    map: []
  },
  getters: {
    companiesList: (state: CompanyState): any => {
      return state.companies.results;
    },
    companiesCount: (state: CompanyState): number => {
      return state.companies.count;
    },
    versionsList: (state: CompanyState): any => {
      return state.versions;
    },
    mapData: (state: CompanyState): any => {
      return state.map;
    }
  },
  mutations: {
    saveCompaniesList: (state: CompanyState, list: any): void => {
      state.companies = list;
    },
    saveVersions: (state: CompanyState, list: any): void => {
      state.versions = list;
    },
    clearCompaniesMap: (state: CompanyState) => {
      state.map = [];
    },
    saveCompaniesMap: (state: CompanyState, list: any): void => {
      state.map = state.map.concat(list.results);
    }
  },
  actions: {
    // Search into the API the list of companies. Use `Authorization` to see that
    // list.
    async getCompaniesList(context: CompanyContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/registry/companies/`;
      if (limit) {
        path += "?limit=" + limit;
      }
      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveCompaniesList", await response.json());
      });
    },
    // Search elements by `query`
    async searchCompanies(context: CompanyContext, query: string) {
      const api = context.rootState.api;

      await fetch(`${api}/registry/companies/search/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ q: query })
      }).then(async response => {
        context.commit("saveCompaniesList", await response.json());
      });
    },
    // Find a company by its `id`
    async findCompany(context: CompanyContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/registry/companies/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Get versions list from a company `id`
    async getVersions(context: CompanyContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/registry/companies/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Create a new company
    async newCompany(context: CompanyContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/registry/companies/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Edit a company. In `data` we have an object got from `findCompany`
    async editCompany(context: CompanyContext, data: any): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;
      let res: any;

      await fetch(`${api}/registry/companies/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Modifiche salvate",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "danger"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return status;
    },
    // Make the call to print a company as PDF file. Use `id` and `fields` as
    // parameters on GET
    async print(context: CompanyContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      let path = `${api}/registry/companies/${data.id}/print/?`;

      for (const field of data.fields) path += field + "&";

      const pdfError = () => {
        context.dispatch(
          "toast",
          {
            message: "Errore nella generazione del PDF",
            type: "error"
          },
          { root: true }
        );
      };

      fetch(path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      })
        .then(async r => ({
          filename: `company-${data.id}.pdf`,
          blob: await r.blob(),
          headers: r.headers,
          status: r.status
        }))
        .then(obj => {
          if (obj.status != 200) {
            pdfError();
            return;
          }
          const newBlob = new Blob([obj.blob], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement("a");
            link.href = objUrl;
            link.download = obj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch(() => {
          pdfError();
        })
        .finally(() => {
          context.commit("changeLoadingStatus", false, { root: true });
        });
    },
    // Create a new line
    async newLine(context: CompanyContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/registry/companies/lines/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Linea salvata",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Remove a line
    async removeCompanyLine(
      context: CompanyContext,
      id: number
    ): Promise<number> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });
      let status = 400;

      await fetch(`${api}/registry/companies/lines/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        status = response.status;
        if (status == 204) {
          context.dispatch(
            "toast",
            {
              message: "Linea rimossa",
              type: "success"
            },
            { root: true }
          );
        } else {
          const res = await response.json();
          context.dispatch(
            "toast",
            {
              message: res.detail,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
      return status;
    },
    // Save a company line
    async saveLine(context: CompanyContext, data: any) {
      if (!data.id) return;
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/registry/companies/lines/${data.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Linea salvata",
              type: "success"
            },
            { root: true }
          );
          context.dispatch("getVersions", data.company);
        } else {
          const res = await response.json();
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    async filterCompanies(context: CompanyContext, filtersList: any) {
      const api = context.rootState.api;

      await fetch(`${api}/registry/companies/filter/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-type": "application/json"
        },
        body: JSON.stringify({ ...filtersList })
      })
        .then(async response => {
          const result = await response.json();
          if (response.status == 200) {
            context.commit("saveCompaniesList", result);
          } else {
            await context.dispatch(
              "toast",
              { details: result, type: "error" },
              { root: true }
            );
          }
        })
        .catch(e => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    },
    // Get the map of the company addresses
    async getMap(context: CompanyContext, filters: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let next = 1;
      let offset = 0;
      let path = `${api}/registry/companies/maps/?limit=1000`;

      if (filters["province"]) {
        for (const province of filters.province) {
          path += `&province=${province}`;
        }
      }

      if (filters["brand"]) {
        for (const brand of filters.brand) {
          path += `&brand=${brand}`;
        }
      }

      if (filters["company"]) {
        path += `&company=${filters.company}`;
      }

      if (filters["season"] && filters["year"]) {
        path += `&season=${filters.season}&year=${filters.year}`;
      }

      context.commit("clearCompaniesMap");

      while (next) {
        await fetch(`${path}&offset=${offset}`, {
          headers: {
            Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
            "Content-Type": "application/json"
          }
        }).then(async response => {
          const data = await response.json();
          context.commit("saveCompaniesMap", data);
          next = data.next;
          offset += data.results.length;
        });
      }

      context.commit("changeLoadingStatus", false, { root: true });
    }
  }
};

export default companies;
