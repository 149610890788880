import { ActionContext } from "vuex";
import { RootState } from "../state";
import moment from "moment";

export interface StatState {
  statsOrders: any;
  stats: any;
  count: number;
}

type StatsContext = ActionContext<StatState, RootState>;

const stats = {
  namespaced: true,
  state: {
    statsOrders: {},
    stats: [],
    count: 0
  },
  getters: {
    statsOrdersList: (state: StatState): any => {
      return state.statsOrders.results;
    },
    count: (state: StatState): number => {
      return state.count;
    },
    statsList: (state: StatState): any => {
      return state.stats;
    }
  },
  mutations: {
    saveStatsOrdersList: (state: StatState, list: any): void => {
      state.statsOrders = list;
    },
    saveStatsCount: (state: StatState, count: number): void => {
      state.count = count;
    },
    saveStatsList: (state: StatState, list: any): void => {
      state.stats = list;
    }
  },
  actions: {
    //Filter page stats
    async filterStats(context: StatsContext, f: any): Promise<any> {
      const api = context.rootState.api;
      let result: any = [];

      await fetch(`${api}/orders/filter/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...f })
      })
        .then(async response => {
          result = await response.json();
          context.commit("saveStatsOrdersList", result);
          context.commit("saveStatsList", result.stats);
          context.commit("saveStatsCount", result.count);
        })
        .catch(e => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    },
    async exportFile(context: StatsContext, data: any): Promise<any> {
      const api = context.rootState.api;
      const f = data.f;
      let path = `${api}/orders/filter/export/?`;

      for (const visible of data.visible)
        path += visible.prop + "=" + visible.id + "&";

      await fetch(path, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...f })
      })
        .then(async response => {
          if (response.ok) {
            const blob = await response.blob();
            const fileURL = URL.createObjectURL(blob);

            // Crea un elemento <a> temporaneo
            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", "Statistiche " + moment().format('DD-MM-YYYY') + "." + f.format); // Imposta il nome del file

            // Aggiungi l'elemento <a> alla pagina e fai clic su di esso per avviare il download
            document.body.appendChild(link);
            link.click();

            // Rimuovi l'elemento <a> dalla pagina
            document.body.removeChild(link);

            // Rilascia l'URL oggetto
            URL.revokeObjectURL(fileURL);
          } else {
            throw new Error("Errore durante l'esportazione'");
          }
        })
        .catch(e => {
          context.dispatch(
            "toast",
            { message: e, type: "error" },
            { root: true }
          );
        });
    }
  }
};
export default stats;
