import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

export interface BonusState {
  bonuses: any;
  versions: any;
}

type BonusContext = ActionContext<BonusState, RootState>;

const bonuses = {
  namespaced: true,
  state: {
    bonuses: [],
    versions: [],
  },
  getters: {
    bonusesList: (state: BonusState): any => {
      return state.bonuses;
    },
    versionsList: (state: BonusState): any => {
      return state.versions;
    },
  },
  mutations: {
    saveBonusesList: (state: BonusState, list: any): void => {
      state.bonuses = list;
    },
    saveVersions: (state: BonusState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // From an `id` search that commercial bonuses
    async findByCommercial(context: BonusContext, id: number) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      await fetch(`${api}/targets/bonuses/?commercial=${id}&limit=50`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
        },
      }).then(async (response) => {
        const data = await response.json();
        context.commit("saveBonusesList", data.results);
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Get versions list from a bonus `id`
    async getVersions(context: BonusContext, id: number) {
      if (!id) {
        context.commit("saveVersions", []);
        return;
      }
      const api = context.rootState.api;
      const path = `${api}/targets/bonuses/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Create or edit a bonus. `data` param is an object to perform POST or PUT
    // action
    async saveBonus(context: BonusContext, data: any): Promise<any> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let path = `${api}/targets/bonuses/`;
      let method = "POST";

      let result;

      if (!data.isNew) {
        path += data.bonusId + "/";
        method = "PUT";
      }

      await fetch(path, {
        method,
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commercial: data.commercial,
          year: data.year,
          bonus: data.bonus,
        }),
      })
        .then(async (response) => {
          result = await response.json();
          if (method == "POST") {
            context.commit(
              "saveBonusesList",
              context.getters["bonusesList"].concat(result)
            );
          }
          context.dispatch(
            "toast",
            {
              message: "Bonus salvato con successo",
              type: "success",
            },
            { root: true }
          );
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
  },
};

export default bonuses;
