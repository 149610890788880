<template lang="pug">
  form 
    section.container
      aside
        #filter
          .containerFilter 
            .reset
              el-button(v-if="reset" @click="filterCompanies(true)") Reset
            ul
              li
                h4 Corrieri
                el-select(
                  filterable clearable multiple
                  v-model="filtersCompaniesList.courier"
                  @change="filterCompanies()"
                )
                  el-option(
                    :label="courier" :value="courier"
                    v-for="courier in couriers"
                    :key="courier"
                  ) {{ courier }}
              li
                h4 Uscite
                el-select(
                  filterable clearable
                  v-model="filtersCompaniesList.brand_trend"
                  @change="filterCompanies()"
                )
                  el-option(
                    :label="brand_trend.name" :value="brand_trend.id"
                    v-for="brand_trend in brandTrendsList"
                    :key="brand_trend.id"
                  )
              li
                h4 Postcode
                el-select(
                  filterable clearable
                  v-model="filtersCompaniesList.postcode"
                  @change="filterCompanies()"
                )
                  el-option(
                    v-for="postcode in postcodes" :key="postcode"
                    :label="postcode" :value="postcode"
                    v-if="postcode.length"
                  )
              li
                h4 Città
                el-select(
                  filterable clearable
                  v-model="filtersCompaniesList.city"
                  @change="filterCompanies()"
                )
                  el-option(
                    v-for="city in cities" :key="city"
                    :label="city" :value="city"
                    v-if="city.length"
                  )
              li
                h4 Regione
                el-select(
                  filterable clearable
                  v-model="filtersCompaniesList.region"
                  @change="filterCompanies()"
                )
                  el-option(
                    v-for="region in regions" :key="region" 
                    :label="region" :value="region"
                    v-if="region.length"
                  )                
</template>

<script>
import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "filterCompanies",
  data() {
    return {
      seasonsForm: ["", ""],
      cities: [],
      postcodes: [],
      provinces: [],
      regions: [],
      reset: false,
      filtersCompaniesList: {},
      couriers: ["BRT", "PosteItaliane", "DHL", "GLS", "FedEx", "UPS"]
    };
  },
  computed: {
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("brandtrends", ["brandTrendsList"]),
    ...mapGetters("companies", ["mapData"])
  },
  mounted() {
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("companies/getMap", {}).then(() => {
      const maps = this.mapData;
      this.postcodes = new Set(maps.map(x => x.postcode));
      this.cities = new Set(maps.map(x => x.city));
      this.regions = new Set(maps.map(x => x.region));
    });
  },
  watch: {
    filtersCompaniesList: {
      handler: function(value) {
        let sentinelCompanies = false;

        for (const key of Object.keys(this.$data.filtersCompaniesList)) {
          if (
            this.$data.filtersCompaniesList[key].length ||
            this.$data.filtersCompaniesList[key].length === undefined
          ) {
            sentinelCompanies = true;
            break;
          }
        }
        this.$data.reset = sentinelCompanies;
      },
      deep: true
    }
  },
  methods: {
    async filterCompanies(reset) {
      if (reset) {
        this.$data.reset = false;
        for (const key of Object.keys(this.$data.filtersCompaniesList)) {
          delete this.$data.filtersCompaniesList[key];
        }
        this.$store.dispatch("companies/getCompaniesList");
      }

      let f = { ...this.$data.filtersCompaniesList };
      for (const key of Object.keys(f)) {
        if (!f[key]) delete f[key];
      }
      this.$store.dispatch("companies/filterCompanies", f);
    }
  }
});
</script>
