<template lang="pug">
  el-form.flex(ref="form" :model="form" :rules="rules")
    section
      el-form-item(label="Email" prop="email" required)
        el-input(v-model="form.email")
        el-checkbox(v-model="form.keep_access") Mantieni accesso
      el-form-item(label="Password" prop="password" required)
        el-input(v-model="form.password" type="password" :show-password="true")
        a(href="#") dimenticata?

    section.right
      el-button(type="primary" @click="submitForm" :loading="inLoading") Accedi
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      rules: {
        email: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur"
          },
          { type: "email", message: "Usa una email valida", trigger: "blur" }
        ],
        password: [
          {
            required: true,
            message: "Riempi questo campo per favore",
            trigger: "blur"
          }
        ]
      },
      form: {
        email: "",
        password: "",
        keep_access: false
      },
      loggedPath: "/dashboard"
    };
  },
  computed: {
    ...mapGetters(["inLoading"])
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.$store.dispatch("auth/login", { ...this.form }).then(res => {
          if (res.status == 200) {
            if (res.data["access"]) {
              this.$store.dispatch("toast", {
                message: "Login effettuato con successo!",
                type: "success"
              });

              setTimeout(() => {
                window.location.href = this.loggedPath;
              }, 2000);
            } else {
              this.$store.dispatch("toast", {
                message:
                  "Ti abbiamo inviato una email con il codice di verifica.",
                type: "success"
              });
            }
          } else {
            this.$store.dispatch("toast", {
              message: res.data,
              type: "error"
            });
          }
        });
      });
    }
  }
});
</script>
