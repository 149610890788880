<template lang="pug">
  main#stats
    b-header
    grey-banner
    search_form(v-if="viewDateSelect")

    el-dialog(
      :visible.sync="exportModel"
      center
      :show-close="false"
      title="Selezionare il formato"
    )
      el-form(:rules="rules")
        el-row 
          el-col(:span="15")
            el-select(
              filterable
              prop="format" 
              clearable
              v-model="filterStats.format"
              style="margin-left: 10px"
            )
              el-option(
                v-for="item in formatOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              )
          el-col(:span="5")
            el-button(type="primary" @click="exportFile" style="margin-left: 10px") Esporta

    section(v-if="viewFormFilter").container
      #stats-sidebar
        .container 
          el-form 
            el-row 
              el-col(:span="6")
                ul 
                  li
                    h4 Stagione
                    el-select(
                      filterable 
                      clearable
                      v-model="filterStats.seasons"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="item in seasonsOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      )
                  li  
                    h4 Azienda Mandante 
                    el-select(
                      filterable 
                      clearable 
                      v-model="filterStats.companies"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="company in companiesList"
                        :label="company.corporate_name"
                        :value="company.id"
                        :key="company.id"
                      )
                  li 
                    h4 Brand 
                    el-select(
                      filterable 
                      clearable 
                      v-model="filterStats.brands"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="brand in brandsList"
                        :label="brand.name"
                        :value="brand.id"
                        :key="brand.id"
                      )
                  li  
                    h4 Uscita 
                    el-select(
                      filterable 
                      clearable 
                      v-model="filterStats.brand_trends"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="brandTrend in brandTrendsList"
                        :label="brandTrend.name"
                        :value="brandTrend.id"
                        :key="brandTrend.id"
                      )
                  li
                    h4 Venditore 
                    el-select(
                      filterable 
                      clearable
                      v-model="filterStats.commercials"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="user in usersList"
                        :label="user.email"
                        :key="user.id"
                        :value="user.id"
                      )
                  li
                    h4 Città
                    el-select(
                      filterable clearable
                      v-model="filterStats.cities"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="city in cities" :key="city"
                        :label="city" :value="city"
                        v-if="city.length"
                      )
                  li 
                    h4 Provincia 
                    el-select(
                      filterable clearable 
                      v-model="filterStats.provinces"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="province in provinces" :key="province"
                        :label="province" :value="province" v-if="province.length"
                      )
                  li
                    h4 Regione
                    el-select(
                      filterable clearable
                      v-model="filterStats.regions"
                      @change="statsFilter()"
                      multiple
                    )
                      el-option(
                        v-for="region in regions" :key="region" 
                        :label="region" :value="region" v-if="region.length"
                      )

              el-col(:span="18")
                el-row.rowTableGeneral
                  el-col(:span="18")
                    el-table(
                      ref="table"
                      :data="statsList.slice(0,1)"
                      v-loading="inLoading"
                      style="width: 95%; border-left: 5px solid #0000ff"
                      stripe
                    )
                      el-table-column(label="Lordo commissionato" prop="gross_import" width="175")
                      el-table-column(label="Netto Comm." prop="total_sum_net" width="125")
                      el-table-column(label="Pezzi totale" prop="number_of_pieces" width="125")
                      el-table-column(label="Provvigioni netto" prop="total_sum_commission_agency" width="175")
                  el-col(:span="6")
                    el-button(type="primary" @click="exportModel = true") Esporta
                    el-button.button(type="primary") Confronta
                el-row.linkWord
                  el-col(:span="2" v-if="returnTable")
                    .backAccounting(@click="editTable('return')")
                  el-col(:span="12")
                    el-link(@click="editTable('colonne')") Colonne/Ordinamento
                    el-link.linkLeft(:underline="false") Raggruppa per
                el-row(style="padding-top: 40px" v-if="editTablePage == 'colonne'")
                  el-col
                    h4 Seleziona le colonne visibili e riordinali in base a come lo si desidera visualizzare
                el-row.row(v-if="editTablePage == 'colonne'")
                  el-col.col-3(:span="12")
                    h3 Visibili
                    draggable.list-group(:list="visible" group="people")
                      .list-group-item(v-for="item in visible" :key="item.name")
                        | {{ item.name }}
                  el-col.col-3(:span="12")
                    h3 Non visibili
                    draggable.list-group(:list="not_visible" group="people")
                      .list-group-item(v-for="item in not_visible" :key="item.name")
                        | {{ item.name }}
                el-row(style="padding-top: 40px" v-if="editTablePage == ''")
                  el-col
                    el-table(
                      ref="table"
                      :data="statsOrdersList"
                      v-loading="inLoading"
                      style="width: 100%"
                      stripe
                    )
                      el-table-column(
                        v-for="item in visible"
                        :label="item.name"
                        :prop="item.prop"
                        width="140"
                        v-if="item.name != 'ID' && item.name != 'Stagione' && item.name != 'Importo' && item.name != 'Nominativo'"
                      )
                      el-table-column(
                        label="ID"
                        width="140"
                        sortable 
                        prop="id"
                        v-else-if="item.name == 'ID'"
                      )
                        template(slot-scope="props")
                          el-link(
                            :underline="false"
                            @click="orderView(props.row.id)"
                          ) {{ props.row.id }}
                      el-table-column(
                        label="Nominativo"
                        width="140"
                        sortable
                        v-else-if="item.name == 'Nominativo'"
                      )
                        template(slot-scope="props")
                          el-link(
                            :underline="false"
                            @click="clientView(props.row.client.id)"
                          ) {{ props.row.client.corporate_name }}
                      el-table-column(
                        label="Stagione" 
                        width="140"
                        v-else-if="item.name == 'Stagione'"
                      )
                        template(slot-scope="props")
                          span {{ props.row.season }} {{ props.row.year }}
                      el-table-column(
                        label="Importo"
                        width="140"
                        v-else-if="item.name == 'Importo'"
                      )
                        template(slot-scope="props")
                          span {{ props.row.gross_import }} €
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import SearchFormStats from "@/components/SearchFormStats.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Stats",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    search_form: SearchFormStats,
    draggable
  },
  data() {
    return {
      visible: [
        { name: "ID", prop: "id", id: 1 },
        { name: "Nominativo", prop: "client.corporate_name", id: 2 },
        { name: "Data", prop: "date", id: 3 },
        { name: "Pagamento", prop: "payment_method.name", id: 4 },
        {
          name: "Mandante",
          prop: "company_line.company.corporate_name",
          id: 5
        },
        { name: "Marchio", prop: "brand.name", id: 6 },
        { name: "Uscita", prop: "brand_trend.name", id: 7 },
        { name: "Stagione", prop: "season", id: 8 },
        { name: "Venditore", prop: "commercial.email", id: 9 },
        { name: "PR MAND", prop: "commission_commercial", id: 10 },
        { name: "Capi", prop: "number_of_pieces", id: 11 },
        { name: "Importo", prop: "gross_import", id: 12 },
        { name: "Indirizzo", prop: "client.legal_address", id: 13 }
      ],
      not_visible: [],
      viewDateSelect: true,
      viewFormFilter: false,
      cities: [],
      exportModel: false,
      provinces: [],
      editTablePage: "",
      returnTable: false,
      regions: [],
      reset: false,
      filterStats: {
        seasons: [],
        companies: [],
        brands: [],
        brand_trends: [],
        commercials: [],
        cities: [],
        provinces: [],
        regions: [],
        date_from: "",
        date_to: ""
      },
      seasonsOption: [
        {
          value: "AI",
          label: "AI"
        },
        {
          value: "PE",
          label: "PE"
        }
      ],
      formatOption: [
        {
          value: "pdf",
          label: "PDF"
        },
        {
          value: "csv",
          label: "CSV"
        }
      ],
      rules: {
        format: [
          {
            required: true,
            message: "Campo obbligatorio",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("companies", ["companiesList"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("brandtrends", ["brandTrendsList"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("clients", ["mapData"]),
    ...mapGetters("stats", ["statsOrdersList", "statsList"]),
    ...mapGetters(["inLoading"])
  },
  mounted() {
    this.$store.dispatch("companies/getCompaniesList");
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("brandtrends/getBrandTrendsList");
    this.$store.dispatch("users/getUsersList");
    this.$store.dispatch("clients/getMap", {}).then(() => {
      const maps = this.mapData;
      this.cities = new Set(maps.map(x => x.city));
      this.provinces = new Set(maps.map(x => x.province));
      this.regions = new Set(maps.map(x => x.region));
    });
  },
  created() {
    if (
      Object.keys(this.$route.query).length == 2 &&
      this.$route.query["from"] != "" &&
      this.$route.query["to"] != ""
    ) {
      this.viewDateSelect = false;
      this.viewFormFilter = true;
      this.filterStats.date_from = this.$route.query.from;
      this.filterStats.date_to = this.$route.query.to;
      let f = { ...this.filterStats };
      this.$store.dispatch("stats/filterStats", f);
    }
  },
  methods: {
    clientView(id){
      window.location.href = "/clienti/" + id
    },
    orderView(id){
      window.location.href = "/ordini/" + id
    },
    exportFile() {
      this.$store.dispatch("stats/exportFile", {
        f: this.filterStats,
        visible: this.visible
      });
    },
    async statsFilter() {
      let f = { ...this.filterStats };
      this.$store.dispatch("stats/filterStats", f);
    },
    editTable(value) {
      if (value == "return") {
        this.$data.editTablePage = "";
        this.$data.returnTable = false;
      } else {
        this.$data.editTablePage = value;
        this.$data.returnTable = true;
      }
    }
  }
});
</script>
