
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import ExpectedOrdersTable from "@/components/ExpectedOrdersTable.vue";
import HistoryTable from "@/components/HistoryTable.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Targets",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "expected-orders-table": ExpectedOrdersTable,
    "history-table": HistoryTable,
  },
  data() {
    return {
      commercial: null,
      year: null,
      season: null,
      bonusId: null,
      bonusCommercial: 0,
      bonusCommercialBackup: 0,
      isANewBonus: true,
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"]),
    ...mapGetters("targets/agencies", ["agencyBudget", "agencyBudgetBackup"]),
    ...mapGetters("targets/agencies", { agencyVersions: "versionsList" }),
    ...mapGetters("targets/bonuses", ["bonusesList"]),
    ...mapGetters("targets/bonuses", { bonusVersions: "versionsList" }),
    ...mapGetters("targets/budgets", ["ordersBudget"]),
    ...mapGetters("targets/orders", ["expectedOrders"]),
  },
  async created() {
    await this.$store.dispatch("auth/findMe");
    if (this.me && this.me.role == 2) {
      this.commercial = this.me.id;
      this.$store.dispatch("targets/bonuses/findByCommercial", this.me.id);
    } else {
      this.$store.dispatch("users/getUsersList");
    }
  },
  watch: {
    async commercial(value) {
      await this.$store.dispatch("targets/bonuses/findByCommercial", value);
      if (this.year) {
        this.searchBonus();

        if (this.season) {
          this.searchOrders();
        }
      }
    },
    year() {
      if (this.season) {
        this.searchOrders();
      }
    },
    agencyBudget(value) {
      if (value.id > 0) {
        this.$store.dispatch("targets/agencies/getVersions", value.id);
      }
    },
  },
  methods: {
    async searchAgencyBudget(from: any) {
      this.searchAgency({ commercial: from.commercial, year: from.year });
      this.searchBudget({ commercial: from.commercial, year: from.year });
    },
    async searchBonus() {
      await this.searchAgencyBudget(this);
      this.isANewBonus = true;
      this.bonusCommercial = 0;
      this.bonusCommercialBackup = 0;
      this.bonusId = null;

      // Reset bonus versions
      this.$store.dispatch("targets/bonuses/getVersions", 0);

      this.clearBudget();
      for (const bonus of this.bonusesList) {
        if (bonus.year == this.year) {
          this.isANewBonus = false;
          this.bonusCommercial = bonus.bonus;
          this.bonusCommercialBackup = bonus.bonus;
          this.bonusId = bonus.id;
          this.$store.dispatch("targets/bonuses/getVersions", bonus.id);
          break;
        }
      }
    },
    searchOrders() {
      this.searchExpectedOrders({
        commercial: this.commercial,
        year: this.year,
        season: this.season,
      });
    },
    createOrEditAgency() {
      this.saveAgency({
        commercial: this.commercial,
        year: this.year,
        budget: this.agencyBudget.budget,
        agencyId: this.agencyBudget.id,
      }).then(async (response) => {
        this.searchAgencyBudget(response);
        this.$store.dispatch(
          "targets/agencies/getVersions",
          this.agencyBudget.id
        );
      });
    },
    createOrEditBonus() {
      this.saveBonus({
        commercial: this.commercial,
        year: this.year,
        bonus: this.bonusCommercial,
        bonusId: this.bonusId,
        isNew: this.isANewBonus,
      }).then(async (response) => {
        this.isANewBonus = false;
        this.bonusId = response.id;
        this.searchAgencyBudget(response);
        this.bonusCommercialBackup = this.bonusCommercial;
        this.$store.dispatch("targets/bonuses/getVersions", response.id);
      });
    },
    ...mapActions("targets/agencies", [
      "saveAgency",
      "searchAgency",
      "clearAgency",
    ]),
    ...mapActions("targets/budgets", ["searchBudget", "clearBudget"]),
    ...mapActions("targets/bonuses", ["saveBonus"]),
    ...mapActions("targets/orders", ["searchExpectedOrders"]),
  },
});
