import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface TaskState {
  tasks: any;
}

type TaskContext = ActionContext<TaskState, RootState>;

const tasks = {
  namespaced: true,
  state: {
    tasks: {}
  },
  getters: {
    tasksList: (state: TaskState): any => {
      return state.tasks.results;
    }
  },
  mutations: {
    saveTasksList: (state: TaskState, list: any): void => {
      state.tasks = list;
    }
  },
  actions: {
    // Search into the API the list of tasks. Use `Authorization` to see that
    // list.
    async getTasksList(context: TaskContext, limit: number | undefined) {
      const api = context.rootState.api;

      let path = `${api}/tasks/`;
      if (limit) {
        path += "?limit=" + limit;
      }
      await fetch(path, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveTasksList", await response.json());
      });
    },
    // Find a task by its `id`
    async findTask(context: TaskContext, id: number): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/tasks/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Edit a task
    async editTask(context: TaskContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/tasks/${data.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 200) {
          context.dispatch(
            "toast",
            {
              message: "Task salvata",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Create a new task
    async newTask(context: TaskContext, data: any): Promise<any> {
      const api = context.rootState.api;

      context.commit("changeLoadingStatus", true, { root: true });

      let res: any;

      await fetch(`${api}/tasks/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(async response => {
        const status = response.status;
        res = await response.json();
        if (status == 201) {
          context.dispatch(
            "toast",
            {
              message: "Task creata",
              type: "success"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: res,
              type: "error"
            },
            { root: true }
          );
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return res;
    },
    // Search tasks
    // Search orders by year, client and season
    async search(context: TaskContext, data: any): Promise<any> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      const body: any = {};
      if (data.commercial) body["commercial"] = data.commercial;
      if (data.client) body["client"] = data.client;
      if (data.company) body["company"] = data.company;
      if (data.is_open) body["is_open"] = data.is_open;

      let result: any = [];

      await fetch(`${api}/tasks/filter/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...data })
      }).then(async response => {
        const r: any = await response.json();
        if (response.status != 200) {
          result = [];
          context.commit("saveTasksList", []);
        } else {
          result = r.results;
          context.commit("saveTasksList", r);
        }
      });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    }
  }
};

export default tasks;
