
import Vue from "vue";

import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "SettingsNewUser",
  props: ["data"],
  data() {
    var validatePasswordConfirm = (rule: any, value: any, callback: any) => {
      if (this.$data.form.password1 !== this.$data.form.password2) {
        callback(new Error("La password non è identica"));
        return;
      }
      callback();
    };
    var validatePassword = (rule: any, value: any, callback: any) => {
      if (this.$data.form.password1.length < 8) {
        callback(new Error("Password debole"));
        return;
      }
      if (this.$data.form.password1.match(/[A-Z]/) === null) {
        callback(new Error("Inserisci almeno un carattere maiuscola"));
        return;
      }
      if (this.$data.form.password1.match(/[a-z]/) === null) {
        callback(new Error("Inserisci almeno un carattere minuscolo"));
        return;
      }
      callback();
    };
    return {
      form: {
        id: null,
        name: "",
        address: "",
        cellular: "",
        email: "",
        vat_number: "",
        fiscal_code: "",
        montly_payment: "",
        start_work_date: "",
        password1: "",
        password2: "",
        calendar_iframe: "",
        role: 0,
        contract_type: ""
      },
      roleOption: [
        {
          label: "Admin",
          role: 1
        },
        {
          label: "Commerciale",
          role: 2
        },
        {
          label: "Capo Commerciale",
          role: 3
        }
      ],
      rules: {
        name: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        start_work_date: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        role: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        contract_type: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" }
        ],
        cellular: [{ required: true, message: "Campo obbligatorio" }],
        fiscal_code: [
          { required: true, message: "Campo obbligatorio" },
          {
            min: 16,
            message: "Codice fiscale non completo",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: "Campo obbligatorio" },
          {
            type: "email",
            message: "Email non valida",
            trigger: ["blur", "change"]
          }
        ],
        password1: [
          { required: true, message: "Campo obbligatorio" },
          {
            validator: validatePassword,
            trigger: "blur"
          }
        ],
        password2: [
          { required: true, message: "Campo obbligatorio" },
          {
            validator: validatePasswordConfirm,
            trigger: "blur"
          }
        ]
      },
      pickerOption: {
        shortcuts: [
          {
            text: "Oggi",
            onClick(picker: any) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler: function(value) {
        if (value.role == 1) {
          this.$data.form.contract_type = "NC";
        } else if (value["contract_type"] && value.contract_type == "NC") {
          this.$data.form.contract_type = "EX";
        }
      },
      deep: true
    },
    data: function(value) {
      if (!value || !value["id"]) this.$set(this.$data, "form", {});
      this.$set(this.$data, "form", value);
    }
  },
  mounted() {
    if (this.$props.data) {
      this.$set(this.$data, "form", this.$props.data);
    }
  },
  methods: {
    async save() {
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        this.form.start_work_date = moment(this.form.start_work_date).format(
          "YYYY-MM-DD"
        );

        if (this.form.id) {
          interface UserForm {
            name: string;
            address: string;
            cellular: string;
            fiscal_code: string;
            montly_payment: string;
            vat_number: string;
            calendar_iframe: string;
            start_work_date: string;
            role: number;
            contract_type: string;
          }

          const data: UserForm = {
            ...this.form
          };

          this.updateProfile({
            profile: data,
            fields: [
              "name",
              "address",
              "cellular",
              "fiscal_code",
              "montly_payment",
              "vat_number",
              "start_work_date",
              "calendar_iframe",
              "role",
              "contract_type"
            ]
          }).then(response => {
            if (response.status == 200)
              window.location.href = "/impostazioni/utenti";
          });
        } else {
          interface UserForm {
            name: string;
            address: string;
            cellular: string;
            email: string;
            fiscal_code: string;
            montly_payment: string;
            vat_number: string;
            start_work_date: string;
            calendar_iframe: string;
            password1: string;
            password2: string;
            role: number;
            contract_type: string;
          }

          const data: UserForm = {
            ...this.form
          };

          this.newUser(data).then(async response => {
            if (response.role) {
              window.location.href = "/impostazioni/utenti";
            }
          });
        }
      }
    },
    ...mapActions("users", ["newUser"]),
    ...mapActions("profiles", ["updateProfile"])
  }
});
