<template lang="pug">
  #settings-sidebar
    .container
      h3 Impostazioni
      ul
        li(:class="{active: pagename == 'SettingsCalendar'}")
          a(href="/impostazioni/calendario") Mio calendario
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsUsers'}"
        )
          a(href="/impostazioni/utenti") Utenti
        li(:class="{active: pagename == 'SettingsBrands'}")
          a(href="/impostazioni/brand") Brand
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsCommercialConditions'}"
        )
          a(href="/impostazioni/condizioni-commerciali") Condizioni commerciali
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsPaymentMethods'}"
        )
          a(href="/impostazioni/metodi-pagamento") Modalità di pagamento
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsAliquote'}"
        )
          a(href="#") Aliquote
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsBrandTrends'}"
        )
          a(href="/impostazioni/brand-trends") Uscite
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsProductSectors'}"
        )
          a(href="#") Settori merceologici
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsCalendars'}"
        )
          a(href="/impostazioni/calendari") Calendari
        li(
          v-if="me.role == 1"
          :class="{active: pagename == 'SettingsDelivery'}"
        )
          a(href="#") Consegna
</template>

<script>
import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SettingsSidebar",
  data() {
    return {
      pagename: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["me"])
  },
  created() {
    this.pagename = this.$route.name;
    this.$store.dispatch("auth/findMe");
  }
});
</script>
