import { RouteConfig } from "vue-router";

import Settings from "@/views/settings/Home.vue";
import SettingsCalendar from "@/views/settings/Calendar.vue";
import SettingsUsers from "@/views/settings/Users.vue";
import SettingsCalendars from "@/views/settings/Calendars.vue";
import SettingsBrands from "@/views/settings/Brands.vue";
import SettingsBrandTrends from "@/views/settings/BrandTrends.vue";
import SettingsPaymentMethods from "@/views/settings/PaymentMethods.vue";
import SettingsCommercialConditions from "@/views/settings/CommercialConditions.vue";

import loginBeforeEnter from "./loginBeforeEnter";
import authAdmin from "./authAdmin";

const routes: Array<RouteConfig> = [
  {
    path: "/impostazioni",
    name: "Settings",
    component: Settings,
    beforeEnter: loginBeforeEnter,
    children: [
      {
        path: "calendario",
        name: "SettingsCalendar",
        component: SettingsCalendar
      },
      {
        path: "utenti",
        name: "SettingsUsers",
        component: SettingsUsers
      },
      {
        path: "calendari",
        name: "SettingsCalendars",
        component: SettingsCalendars,
        beforeEnter: authAdmin
      },
      {
        path: "brand",
        name: "SettingsBrands",
        component: SettingsBrands
      },
      {
        path: "brand-trends",
        name: "SettingsBrandTrends",
        component: SettingsBrandTrends,
        beforeEnter: authAdmin
      },
      {
        path: "metodi-pagamento",
        name: "SettingsPaymentMethods",
        component: SettingsPaymentMethods,
        beforeEnter: authAdmin
      },
      {
        path: "condizioni-commerciali",
        name: "SettingsCommercialConditions",
        component: SettingsCommercialConditions,
        beforeEnter: authAdmin
      }
    ]
  }
];

export default routes;
