import { ActionContext } from "vuex";
import { RootState } from "../state";

export interface ProfileState {
  profiles: any;
}

type ProfileContext = ActionContext<ProfileState, RootState>;

const profiles = {
  namespaced: true,
  state: {
    profiles: []
  },
  getters: {
    profilesList: (state: ProfileState): any => {
      return state.profiles.results;
    }
  },
  mutations: {
    saveProfilesList: (state: ProfileState, list: any): void => {
      state.profiles = list;
    }
  },
  actions: {
    // Get profiles list. If `limit` is defiend, add it to the request
    async getProfilesList(context: ProfileContext, limit: number | null) {
      const api = context.rootState.api;

      if (!limit) {
        limit = 24; // Default value
      }

      await fetch(`${api}/profiles/?limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        context.commit("saveProfilesList", await response.json());
      });
    },
    // Find a profile and return it if found. By its `id`
    async findProfile(
      context: ProfileContext,
      id: number | string
    ): Promise<any> {
      const api = context.rootState.api;
      let result = {
        data: {},
        status: 404
      };

      await fetch(`${api}/profiles/${id}/`, {
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`
        }
      }).then(async response => {
        result = {
          data: await response.json(),
          status: response.status
        };
      });

      return result;
    },
    // Update a profile. In `data` you can find an object with `profile` and
    // `fields`.
    // `profile` is the object returned from the db and `fields` is an array of
    // string which contains the list of attributes to edit.
    // Returns the response data and status.
    async updateProfile(context: ProfileContext, data: any): Promise<any> {
      const api = context.rootState.api;
      const body: any = {};
      let result = { data: {}, status: 400 };

      for (const field of data.fields) {
        body[field] = data.profile[field];
      }

      const path = `${api}/profiles/${data.profile.id}/`;

      await fetch(path, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-type": "application/json"
        },
        body: JSON.stringify(body)
      }).then(async response => {
        result = {
          data: data.profile.id,
          status: response.status
        };

        if (result.status != 200) {
          context.dispatch(
            "toast",
            {
              message: await response.json(),
              type: "error"
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "toast",
            {
              message: "Modifiche per " + data.profile.email + " salvate",
              type: "success"
            },
            { root: true }
          );
        }
      });

      return result;
    }
  }
};

export default profiles;
