<template lang="pug">
    main#stats
      section.container
        #stats-data
          .container 
            el-form(ref="form" :model="form")
              el-row 
                el-col(:span="8")
                  el-form-item(label="DATA" prop="date_from")
                    el-date-picker.date(
                      name="from"
                      type="date"
                      v-model="form.date_from"
                      el-icon-date
                      placeholder="da 0000-00-00"
                      format="yyyy-MM-dd"
                      :picker-options="pickerOptionFrom"
                    )
                el-col(:span="8")
                  el-form-item(prop="date_to")
                    el-date-picker.date(
                      name="to"
                      type="date"
                      v-model="form.date_to"
                      style="margin-top: 40px"
                      placeholder="a 0000-00-00"
                      format="yyyy-MM-dd"
                      :picker-options="pickerOptionTo"
                    )
                el-col(:span="3" )
                  el-form-item(v-if="form.date_to != '' || form.date_from != ''" )
                    .delete(@click="deleteDate()") cancella
                el-col(:span="4")
                  el-form-item
                    el-button.button(type="primary" native-type="submit") CERCA
  
  
  </template>
  
  <script>
  import Vue from "vue";
  
  import Header from "@/components/Header.vue";
  import GreyBanner from "@/components/GreyBanner.vue";
  
  import { mapGetters, mapActions } from "vuex";
  
  export default Vue.extend({
    name: "Maps",
    components: {
      "b-header": Header,
      "grey-banner": GreyBanner,
    },
    data() {
      var self = this
      return {
        form: {
          date_from: "",
          date_to: ""
        },
        pickerOptionTo: {
          disabledDate(time) {
            if (self._data.form.date_from !== "") {
              return time.getTime() < new Date(self._data.form.date_from).getTime();
            } else {
              return false;
            }
          }
        },
        pickerOptionFrom: {
          disabledDate(time) {
            if (self._data.form.date_to !== "") {
              return time.getTime() > new Date(self._data.form.date_to).getTime();
            } else {
              return false;
            }
          }
        }
      };
    },
    methods: {
      async deleteDate() {
        this.form.date_from = "";
        this.form.date_to = ""
      },
    },
  });
  </script>
  