
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Settings",
  data() {
    return { hasErrors: false };
  },
  computed: {
    ...mapGetters("auth", ["me"]),
  },
  methods: {
    save() {
      this.updateProfile({
        profile: this.me,
        fields: ["calendar_iframe", "role"],
      }).then((response) => {
        this.hasErrors = response.status != 200;
      });
    },
    ...mapActions("profiles", ["updateProfile"]),
  },
});
