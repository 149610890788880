
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "SettingsCommercialConditions",
  data() {
    return { commercialConditionName: "" };
  },
  mounted() {
    this.getCommercialConditionsList();
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("commercialconditions", ["commercialConditionsList"])
  },
  methods: {
    saveNewCommercialCondition() {
      this.newCommercialCondition({
        condition: this.commercialConditionName
      }).then(() => (this.commercialConditionName = ""));
    },
    ...mapActions("commercialconditions", [
      "getCommercialConditionsList",
      "newCommercialCondition",
      "deleteCommercialCondition"
    ])
  }
});
