<template lang="pug">
  el-form.flex(ref="form" :model="form")
    section
      el-form-item(v-for="i in 6" :key="i")
        el-input.otp-input(
          :ref="'code'+(i-1)"
          v-model="form.code[i-1]"
          type="tel"
          inputmode="numeric"
          pattern="[0-9]"
          @input="checkCode($event, i-1)"
        )

    section.right
      el-button(type="primary" @click="submitForm" :loading="inLoading") Accedi
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "OtpBox",
  props: ["reference"],
  data() {
    return {
      form: {
        code: [null, null, null, null, null, null],
      },
      loggedPath: "/dashboard",
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
  },
  mounted() {
    if (this.$props.reference) this.loggedPath = this.$props.reference;
  },
  methods: {
    checkCode(event, index) {
      if (event.length > 1) {
        const n = event.length;
        for (let i = 0, j = index; i < n; ++i, ++j) {
          this.form.code[j] = event[i];
          this.checkCode(event[i], j);
        }
        return;
      }
      const num = Number(this.form.code[index]);
      if (!(num >= 0 && num <= 9)) {
        this.$set(this.form.code, index, null);
      } else if (index < 5) {
        this.$refs["code" + (index + 1)][0].focus();
      }

      if (this.form.code.filter((x) => x == null).length == 0) {
        this.submitForm();
      }
    },
    submitForm() {
      if (this.form.code.filter((x) => x == null || x == "").length > 0) {
        this.$store.dispatch("toast", {
          message: "Non hai inserito un codice valido",
          type: "error",
        });
        return;
      }

      const code = this.form.code.join("");
      this.$store.dispatch("auth/login2fa", code).then((res) => {
        if (res.status == 200) {
          this.$store.dispatch("toast", {
            message: "Login effettuato con successo!",
            type: "success",
          });

          setTimeout(() => {
            window.location.href = this.loggedPath;
          }, 2000);
        } else {
          this.$store.dispatch("toast", {
            message: res.data.error,
            type: "error",
          });
        }
      });
    },
  },
});
</script>
