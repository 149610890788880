<template lang="pug">
  main#tradefairs
    b-header
    #blue-top.edit
      .container
        h2.title-underline(v-if="isNew") Crea campionario
        h2.title-underline(v-else) Modifica campionario

    .container.main-tradefair
      el-form(ref="form" :model="form" :rules="rules" v-loading="inLoading")
        el-row
          el-col(:span="6")
            el-form-item(label="Tipo movimento" prop="transaction_type")
              el-select(
                v-model="form.transaction_type"
              )
                el-option(
                  v-for="transactionType in transactionTypes"
                  :key="transactionType.key"
                  :label="transactionType.label"
                  :value="transactionType.key"
                )
          el-col(:span="6")
            el-form-item(label="Data" prop="date")
              el-date-picker(
                type="date"
                placeholder="Seleziona una data"
                v-model="form.date"
                format="dd/MM/yyyy"
              )
          el-col(:span="6")
            el-form-item(label="Num. documento" prop="document_number")
              el-input-number(:min="100" :max="999999" :controls="false" v-model="form.document_number")
          el-col(:span="6")
            el-form-item(label="Uscita" prop="brand_trend")
              el-select(
                v-model="form.brand_trend"
              )
                el-option(
                  :label="trend.name"
                  :value="trend.id"
                  v-for="trend in brandTrendsList"
                  :key="trend.id"
                  v-if="trend.is_available"
                )
        el-row
          el-col(:span="6")
            el-form-item(label="Stagione" prop="season")
              el-select(v-model="form.season" placeholder="Seleziona")
                el-option(label="Primavera-estate" value="PE")
                el-option(label="Autunno-inverno" value="AI")
          el-col(:span="6")
            el-form-item(label="Anno" prop="year")
              el-date-picker(
                type="year"
                placeholder="Seleziona un anno"
                v-model="form.year"
              )
          el-col(:span="6")
            el-form-item(label="Mandante" prop="company")
              el-select(
                v-model="form.company"
                placeholder="Seleziona"
                filterable
              )
                el-option(
                  :label="company.corporate_name"
                  :value="company"
                  v-for="company in companiesList"
                  :key="company.id"
                )
          el-col(:span="6")
            el-form-item(label="Marchio" prop="brand")
              el-select(
                v-model="form.brand"
                filterable
                :disabled="!form.company"
              )
                el-option(
                  :label="brand.name"
                  :value="brand.id"
                  v-for="brand in brandsList"
                  :key="brand.id"
                  v-if="checkBrand(brand.id)"
                )
        el-row
          el-col(:span="6")
            el-form-item(label="Quantità" prop="qta")
              el-input-number(type="qta" :controls="false" v-model="form.qta")
          el-col(:span="6")
            el-form-item(label="Importo" prop="amount")
              el-input(type="number" v-model="form.amount")
                template(slot="prepend") €

        el-button.center(type="primary" @click="save") 
          span(v-if="isNew") Crea
          span(v-else) Salva
</template>

<script>
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";

import { mapGetters, mapActions } from "vuex";

import moment from "moment";

export default Vue.extend({
  name: "TradeFair",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner
  },
  props: ["id"],
  data() {
    return {
      isNew: false,
      form: {},
      transactionTypes: [
        { key: "UP", label: "Carico campionario" },
        { key: "RE", label: "Reso al mandante" },
        { key: "SC", label: "Vendita cliente" },
        { key: "RC", label: "Reso cliente" },
        { key: "UC", label: "Carico al venditore" },
        { key: "RR", label: "Reso del venditore" },
        { key: "FA", label: "Capi omaggio" },
        { key: "PA", label: "Capi personali" }
      ],
      rules: {
        transaction_type: [{ required: true, message: "Campo obbligatorio" }],
        date: [{ required: true, message: "Campo obbligatorio" }],
        document_number: [{ required: true, message: "Campo obbligatorio" }],
        brand_trend: [{ required: true, message: "Campo obbligatorio" }],
        season: [{ required: true, message: "Campo obbligatorio" }],
        year: [{ required: true, message: "Campo obbligatorio" }],
        company: [{ required: true, message: "Campo obbligatorio" }],
        brand: [{ required: true, message: "Campo obbligatorio" }],
        qta: [{ required: true, message: "Campo obbligatorio" }],
        amount: [{ required: true, message: "Campo obbligatorio" }]
      }
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("companies", ["companiesList"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("brandtrends", ["brandTrendsList"])
  },
  async created() {
    if (this.$props.id != "nuovo") {
      this.$store
        .dispatch("tradefairs/findTradefair", this.$props.id)
        .then(response => {
          if (response.status != 200) {
            window.location.href = "/campionario";
          } else {
            this.form = {
              ...response.data,
              brand: response.data.brand.id,
              brand_trend: response.data.brand_trend.id,
              year: response.data.year + "-01-01"
            };
          }
        });
    } else {
      this.isNew = true;
    }

    this.$store.dispatch("companies/getCompaniesList", 1000);
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("brandtrends/getBrandTrendsList");
  },
  methods: {
    async save() {
      const valid = this.$refs.form.validate();
      if (await valid) {
        const data = { ...this.form };
        data.date = moment(this.form.date).format("YYYY-MM-DD");
        data.year = moment(this.form.year).format("YYYY");
        data.company = this.form.company.id;
        if (this.isNew) {
          this.$store
            .dispatch("tradefairs/newTradeFair", { ...data })
            .then(() => {
              window.location.href = "/campionario";
            });
        } else {
          this.$store.dispatch("tradefairs/editTradeFair", { ...data });
        }
      } else {
        return false;
      }
    },
    checkBrand(brandId) {
      if (this.form.company) {
        return this.form.company.brands.indexOf(brandId) >= 0;
      }

      return false;
    }
  }
});
</script>
