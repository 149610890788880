
import Vue from "vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "PlanBrandNew",
  props: ["plan"],
  data() {
    return {
      form: {},
      rules: {
        status: [{ required: true, message: "Campo obbligatorio" }],
        commercial: [{ required: true, message: "Campo obbligatorio" }]
      }
    };
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"])
  },
  watch: {
    plan: function(value) {
      this.checkPlan(value);
    }
  },
  mounted() {
    this.checkPlan(this.plan);
  },
  methods: {
    checkPlan(value: any) {
      if (value.plan) {
        this.$set(this.$data, "form", {
          status: value.status,
          commercial: value.commercial,
          note: value.note
        });
      }
    },
    async onSubmit() {
      if (this.me.role == 2) this.$data.form.commercial = this.me.id;
      const valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();

      if (await valid) {
        if (this.plan.plan) {
          this.editPlanning({ ...this.plan, ...this.form }).then(status => {
            if (status == 200) {
              this.searchPlannings(null);
            }
          });
        } else {
          this.newPlanning({ ...this.plan, ...this.form }).then(status => {
            if (status == 201) {
              this.searchPlannings(null);
            }
          });
        }
      }
    },
    setStatus(status: string) {
      this.$set(this.$data.form, "status", status);
    },
    ...mapActions("planning", [
      "newPlanning",
      "editPlanning",
      "searchPlannings"
    ])
  }
});
