import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

interface OrdersBudget {
  actual_orders_budget: number | null;
  expected_orders_budget: number | null;
}

export interface BudgetState {
  budgets: OrdersBudget;
}

type BudgetContext = ActionContext<BudgetState, RootState>;

const budgets = {
  namespaced: true,
  state: {
    budgets: {},
  },
  getters: {
    ordersBudget: (state: BudgetState): OrdersBudget => {
      return state.budgets;
    },
  },
  mutations: {
    saveBudgets: (state: BudgetState, data: OrdersBudget): void => {
      state.budgets = data;
    },
  },
  actions: {
    // Set `budgets` to all 0 for new budget
    clearBudget(context: BudgetContext) {
      context.commit("saveBudgets", {
        actual_orders_budget: 0,
        expected_orders_budget: 0,
      });
    },
    // Search a budget by commercial and year
    async searchBudget(context: BudgetContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      const { commercial, year } = data;

      await fetch(`${api}/targets/budgets/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commercial,
          year,
        }),
      }).then(async (response) => {
        context.commit("saveBudgets", await response.json());
      });

      context.commit("changeLoadingStatus", false, { root: true });
    },
  },
};

export default budgets;
