import { ActionContext } from "vuex";
import { RootState } from "@/store/state";

export interface AgencyState {
  agency: any;
  versions: any;
  agencyPrev: any; // Used to check differences from the object above
}

type AgencyContext = ActionContext<AgencyState, RootState>;

const agencies = {
  namespaced: true,
  state: {
    agency: {},
    versions: [],
    agencyPrev: {},
  },
  getters: {
    agencyBudget: (state: AgencyState): any => {
      return state.agency;
    },
    versionsList: (state: AgencyState): any => {
      return state.versions;
    },
    agencyBudgetBackup: (state: AgencyState): any => {
      return state.agencyPrev;
    },
  },
  mutations: {
    saveAgency: (state: AgencyState, data: any): void => {
      state.agency = data;
      state.agencyPrev = JSON.parse(JSON.stringify(data));
    },
    saveVersions: (state: AgencyState, list: any): void => {
      state.versions = list;
    },
  },
  actions: {
    // Reset agency data
    clearAgency(context: AgencyContext) {
      context.commit("saveAgency", { id: 0, budget: 0 });
      context.commit("saveVersions", []);
    },
    // Search a agency by commercial and year
    async searchAgency(context: AgencyContext, data: any) {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });
      const { commercial, year } = data;
      let result = null;

      await fetch(`${api}/targets/agency/search/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commercial,
          year,
        }),
      })
        .then(async (response) => {
          result = await response.json();
          if (response.status != 200) {
            context.dispatch("clearAgency");
          } else {
            context.commit("saveAgency", result);
          }
        })
        .catch(() => {
          context.dispatch("clearAgency");
        });

      context.commit("changeLoadingStatus", false, { root: true });
    },
    // Get versions list from an agency `id`
    async getVersions(context: AgencyContext, id: number) {
      const api = context.rootState.api;
      const path = `${api}/targets/agency/${id}/versions/`;
      context
        .dispatch("revisions/getVersions", path, { root: true })
        .then((result: any) => {
          context.commit("saveVersions", result);
        });
    },
    // Create or edit an agency. `data` param is an object to perform POST or PUT
    // action
    async saveAgency(context: AgencyContext, data: any): Promise<any> {
      const api = context.rootState.api;
      context.commit("changeLoadingStatus", true, { root: true });

      let path = `${api}/targets/agency/`;
      let method = "POST";

      let result;

      if (data.agencyId) {
        path += data.agencyId + "/";
        method = "PUT";
      }

      await fetch(path, {
        method,
        headers: {
          Authorization: `Bearer ${context.rootGetters["auth/accessToken"]}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commercial: data.commercial,
          year: data.year,
          budget: data.budget,
        }),
      })
        .then(async (response) => {
          result = await response.json();
          context.commit("saveAgency", result);
          context.dispatch(
            "toast",
            {
              message: "Salvato con successo",
              type: "success",
            },
            { root: true }
          );
        })
        .catch((e) => {
          context.dispatch(
            "toast",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        });

      context.commit("changeLoadingStatus", false, { root: true });

      return result;
    },
  },
};

export default agencies;
