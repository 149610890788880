
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import AppointmentNew from "@/components/AppointmentNew.vue";

import { mapGetters } from "vuex";

import moment from "moment";
import { unitOfTime } from "moment";

export default Vue.extend({
  name: "Planning",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "appointment-new": AppointmentNew
  },
  data() {
    return {
      filter: "this_week",
      table: {
        days: ["1970-01-01"],
        months: ["January"],
        hours: {}
      },
      newAppointmentModal: false,
      appointmentInfo: {
        id: null,
        plan: null,
        date: null,
        date_type: null,
        location: null,
        note: null,
        confirmed: false
      },
      start: "",
      end: "",
      filterStatus: "all"
    };
  },
  computed: {
    ...mapGetters(["inLoading"])
  },
  watch: {
    newAppointmentModal: function() {
      this.getCalendar([this.start, this.end]);
    }
  },
  created() {
    this.filter = "this_week";
    this.filterWeek();
  },
  methods: {
    filterWeek() {
      let momentRange = moment();
      if (this.filter == "next_week") {
        momentRange.add(1, "week");
      } else if (this.filter == "two_weeks") {
        momentRange.add(2, "weeks");
      }
      const monday = momentRange.startOf("isoweek" as unitOfTime.StartOf);
      const start = monday.format("YYYY-MM-DD");
      const end = monday.add(7, "days").format("YYYY-MM-DD");
      this.start = start;
      this.end = end;
      this.getCalendar([start, end]);
    },
    getCalendar(range?: [string, string]) {
      if (!range) {
        const start = moment(this.start);
        const end = moment(this.end);

        if (start > end) {
          return this.filterWeek();
        }

        this.start = start.format("YYYY-MM-DD");
        this.end = end.format("YYYY-MM-DD");

        range = [this.start, this.end];
      }
      this.$store
        .dispatch("planning/appointments", {
          start: range[0],
          end: range[1]
        })
        .then((response: any) => {
          const days: string[] = Object.keys(response);
          this.table.days = days;
          type HoursT = {
            [key: string]: any;
          };
          const tableHours: HoursT = {
            "08": [],
            "09": [],
            "10": [],
            "11": [],
            "12": [],
            "13": [],
            "14": [],
            "15": [],
            "16": [],
            "17": [],
            "18": [],
            "19": [],
            "20": [],
            "21": []
          };

          const months = [""];
          for (const day of days) {
            const month = day.substr(0, 7);
            if (months.indexOf(month) < 0) {
              months.push(month);
            } else {
              months.push("");
            }
            for (const hour of Object.keys(tableHours)) {
              if (this.filterStatus != "all") {
                tableHours[hour].push(
                  response[day][hour].filter((x: any) => x.confirmed)
                );
              } else {
                tableHours[hour].push(response[day][hour]);
              }
            }
          }

          for (let i = 0; i < months.length; ++i) {
            if (months[i] != "") {
              months[i] = moment(months[i]).format("MMMM YYYY");
            }
          }

          this.$set(this.table, "hours", tableHours);
          this.$set(this.table, "months", months);
        });
    },
    editAppointment(appointment: any) {
      this.$set(this.$data, "appointmentInfo", {
        id: appointment.id,
        plan: appointment.plan ? appointment.plan.id : null,
        date: appointment.date,
        date_type: appointment.date_type,
        location: appointment.location,
        note: appointment.note,
        confirmed: appointment.confirmed
      });

      this.newAppointmentModal = true;
    },
    newAppointment(dayIndex: number, hour: string) {
      const date = this.table.days[dayIndex] + "T" + hour + ":00:00Z";
      this.editAppointment({ date });
    }
  }
});
