import store from "@/store";

// eslint-disable-next-line
const authAdmin = async (to: any, from: any, next: any) => {
  await store.dispatch("auth/findMe");
  const me = store.getters["auth/me"];

  if (me.role == 1) {
    next();
  } else {
    next({ name: "Dashboard" });
  }
};

export default authAdmin;
