
import Vue from "vue";

import Header from "@/components/Header.vue";
import GreyBanner from "@/components/GreyBanner.vue";
import PlanBrandNew from "@/components/PlanBrandNew.vue";
import AppointmentNew from "@/components/AppointmentNew.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Planning",
  components: {
    "b-header": Header,
    "grey-banner": GreyBanner,
    "plan-brand-new": PlanBrandNew,
    "appointment-new": AppointmentNew
  },
  data() {
    return {
      filters: {
        season: null,
        year: null,
        clients: [],
        commercials: [],
        brands: []
      },
      availableBrands: [],
      availableClients: [],
      newPlanModal: false,
      planInfo: {
        year: null,
        season: null,
        client: 0,
        brand: 0,
        plan: 0,
        status: null,
        commercial: null,
        note: null
      },
      newAppointmentModal: false,
      appointmentInfo: {
        id: null,
        plan: null,
        date: null,
        date_type: null,
        location: null,
        note: null,
        confirmed: false
      }
    };
  },
  created() {
    this.$store.dispatch("clients/getClientsList");
    this.$store.dispatch("brands/getBrandsList");
    this.$store.dispatch("auth/findMe").then(() => {
      if (this.me && this.me.role == 1) {
        this.$store.dispatch("users/getUsersList");
      }
    });
  },
  computed: {
    ...mapGetters(["inLoading"]),
    ...mapGetters("brands", ["brandsList"]),
    ...mapGetters("clients", ["clientsList"]),
    ...mapGetters("planning", ["plansList"]),
    ...mapGetters("users", ["usersList"]),
    ...mapGetters("auth", ["me"])
  },
  watch: {
    clientsList: function(value) {
      this.availableClients = value;
    },
    plansList: function() {
      this.newPlanModal = false;
      this.newAppointmentModal = false;
    }
  },
  methods: {
    clientTypeDescription(status: string): string {
      const table: any = {
        CA: "Attivo",
        CC: "Chiuso",
        CP: "Potenziale",
        CS: "Da non servire",
        CI: "Inattivo",
        CU: "In sospeso"
      };

      return table[status] || "";
    },
    clearFilters() {
      this.filters.clients = this.filters.commercials = this.filters.brands = [];
      this.filterPlanning();
    },
    async filterPlanning() {
      if (!(this.filters.season && this.filters.year)) return;

      if (this.filters.clients.length > 0) {
        const availableClients: any = [];
        for (const client of this.clientsList) {
          for (const id of this.filters.clients) {
            if (id == client.id) {
              availableClients.push(client);
            }
          }
        }
        this.availableClients = availableClients;
      } else {
        this.availableClients = this.clientsList;
      }

      if (this.filters.brands.length > 0) {
        const availableBrands: any = [];
        for (const brand of this.brandsList) {
          for (const id of this.filters.brands) {
            if (id == brand.id && brand.is_available) {
              availableBrands.push(brand);
            }
          }
        }
        this.availableBrands = availableBrands;
      } else {
        this.availableBrands = this.brandsList.filter(
          (x: any) => x.is_available
        );
      }

      await this.$store.dispatch("planning/searchPlannings", this.filters);
    },
    editPlan(plan: any) {
      const client =
        typeof plan.client == "object" ? plan.client.id : plan.client;
      const brand = typeof plan.brand == "object" ? plan.brand.id : plan.brand;
      const commercial =
        typeof plan.commercial == "object"
          ? plan.commercial.id
          : plan.commercial;

      this.planInfo = {
        year: this.filters.year,
        season: this.filters.season,
        client,
        brand,
        plan: plan.id,
        status: plan.status,
        commercial,
        note: plan.note
      };
      this.newPlanModal = true;
    },
    newPlan(client: number, brand: number) {
      this.editPlan({ client, brand });
    },
    editAppoinment(appointment: any) {
      this.$set(this.$data, "appointmentInfo", {
        id: appointment.id,
        plan: appointment.plan,
        date: appointment.date,
        date_type: appointment.date_type,
        location: appointment.location,
        note: appointment.note,
        confirmed: appointment.confirmed
      });
      this.newAppointmentModal = true;
    },
    newAppointment(date_type: string, plan: number) {
      this.editAppoinment({
        date_type,
        plan,
        date: null,
        location: null,
        id: null,
        note: "",
        confirmed: false
      });
    },
    ...mapActions("planning", ["deletePlanning"])
  }
});
