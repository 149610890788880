<template lang="pug">
  el-table.nohover.history(
    :data="table"
  )
    el-table-column(prop="date" label="")
      template(slot-scope="scope")
        span {{ scope.row.revision.date_created | moment('DD/MM/YYYY HH:mm') }}
    el-table-column(prop="owner" label="")
      template(slot-scope="scope")
        span {{ scope.row.revision.user.email }}
    el-table-column(prop="differences" label="")
      template(slot-scope="scope")
        .diff-version(v-if="scope.row.differences.length > 0")
          span(v-if="pk") {{ scope.row.pk }} : 
          span {{ scope.row.differences[0].field }}:
          s {{ scope.row.differences[0].before }}
          span →
          b {{ scope.row.differences[0].after }}
        span(v-if="scope.row.revision.comment") {{ scope.row.revision.comment }}
  </template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "HistoryTable",
  props: ["data", "pk"],
  data() {
    return {
      table: []
    };
  },
  watch: {
    data: function(value) {
      this.getTable(value);
    }
  },
  methods: {
    getTable(list) {
      this.table = list ?? [];
      this.table = this.table.filter(
        x => x.differences.length > 0 || x.revision.comment
      );
      if (list.length > 0) this.table.unshift(list[0]);
      this.table.reverse();
    }
  }
});
</script>
